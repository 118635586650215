import icon1 from '../../assets/images/architecture_images/icon1.svg';
import icon2 from '../../assets/images/architecture_images/icon2.svg';
import icon3_Old from '../../assets/images/architecture_images/icon3_Old.svg';
import icon3 from '../../assets/images/architecture_images/icon3.svg';
import icon4 from '../../assets/images/architecture_images/icon4.svg';
import icon5 from '../../assets/images/architecture_images/icon5.svg';
import icon6 from '../../assets/images/architecture_images/icon6.svg';
import icon7 from '../../assets/images/architecture_images/icon7.svg';
import icon8 from '../../assets/images/architecture_images/icon8.svg';

const IS_NEW_ICON = process.env.REACT_APP_NEW_IMAGES_20899 === 'true';

const visibleData = [
  {
    id: 1,
    icon: icon1,
    description: 'Устройство стяжки с шумоизоляцией',
  },
  {
    id: 2,
    icon: icon2,
    description: 'Устройство гидроизоляции в С/У, стяжки в С/У',
  },
  {
    id: 3,
    icon: IS_NEW_ICON ? icon3 : icon3_Old,
    description: 'Устройство системы механической централизованной системы вентиляции',
  },
  {
    id: 4,
    icon: icon4,
    description: 'Разводка сетей водоснабжения и канализации (кухня)',
  },
  {
    id: 5,
    icon: icon5,
    description: 'Монтаж системы отопления, с установкой приборов отопления',
  },
  {
    id: 6,
    icon: icon6,
    description: 'Монтаж квартирного щита электроснабжения',
  },
  {
    id: 7,
    icon: icon7,
    description: 'Подготовка стен и оконных откосов под финишную отделку',
  },
  {
    id: 8,
    icon: icon8,
    description: 'Выравнивание стен (кроме С/У)',
  },
];

const hideData = [
  {
    id: 9,
    description: 'Возведение внутренних перегородок на всю высоту помещения',
  },
  {
    id: 10,
    description: 'Обеспыливание потолка',
  },
  {
    id: 11,
    description: 'Устройство точки подключения дренажной системы для внутренних блоков',
  },
  {
    id: 12,
    description: 'Разводка слаботочных сетей (кабельное ТВ, интернет, домофон, умный дом), с установкой подрозетников',
  },
  {
    id: 13,
    description: 'Установка автоматической пожарной сигнализации',
  },
  {
    id: 14,
    description: 'Ввод фреонопроводов под кондиционер',
  },
  {
    id: 15,
    description:
      'Разводка электрики от квартирного щита до места установки электроприборов, с установкой подрозетников',
  },
];

const hideDataMobile = [
  {
    id: 9,
    description: 'Возведение внутренних перегородок на всю высоту помещения',
  },
  {
    id: 13,
    description: 'Установка автоматической пожарной сигнализации',
  },
  {
    id: 10,
    description: 'Обеспыливание потолка',
  },
  {
    id: 14,
    description: 'Ввод фреонопроводов под кондиционер',
  },
  {
    id: 11,
    description: 'Устройство точки подключения дренажной системы для внутренних блоков',
  },
  {
    id: 12,
    description: 'Разводка слаботочных сетей (кабельное ТВ, интернет, домофон, умный дом), с установкой подрозетников',
  },
  {
    id: 15,
    description:
      'Разводка электрики от квартирного щита до места установки электроприборов, с установкой подрозетников',
  },
];

export { hideData, hideDataMobile, visibleData };
