import { useParams } from 'react-router-dom';
import wordEndings from '../../../tools/_word-endings';
import style from './VisualInfo.module.scss';

function VisualInfo({ activeFloor, activeFlat, data, activeBulk }) {
  const { houseId, bulkId, floorId } = useParams();

  const PieceOfInfo = ({ info }) => {
    return (
      <>
        <span className={style.visualInfo__text}>{info}</span>
        <div className={style.visualInfo__dot} />
      </>
    );
  };

  const getInfo = (bulkId, type) => {
    const bulk = data?.bulks?.find(bulk => bulk.id === bulkId);
    const flatsLength = data.flats.filter(item => item.bulk.id === bulkId).length;
    if (type === 'keyDate') return bulk.keyDate;
    if (type === 'floorsCount') return bulk.floorsCount;
    if (type === 'decoration') return bulk.decoration;
    if (type === 'name') return bulk.name;
    if (type === 'flatsLength') return flatsLength;
  };

  return (
    <>
      <div className={style.visualInfo}>
        {houseId && !floorId && <PieceOfInfo info={houseId === '1' ? 'Очередь 1' : 'Очередь 2'} />}
        {bulkId && <PieceOfInfo info={getInfo(activeBulk, 'name')} />}
        {((!floorId && activeFloor) || !bulkId) && (
          <PieceOfInfo
            info={'Ключи до' + ' ' + getInfo(activeBulk, 'keyDate')?.replace(/-/gi, '.').split('.').reverse().join('.')}
          />
        )}
        {((!activeFloor && bulkId) || !bulkId) && (
          <PieceOfInfo
            info={(floorId ? floorId : getInfo(activeBulk, 'floorsCount')) + ' ' + (floorId ? 'этаж' : 'этажей')}
          />
        )}
        {floorId && activeFlat && <PieceOfInfo info={'№' + '' + activeFlat} />}
        {floorId && (
          <span>
            {'Ключи до' + ' ' + getInfo(activeBulk, 'keyDate')?.replace(/-/gi, '.').split('.').reverse().join('.')}
          </span>
        )}
        {bulkId && !floorId && !activeFloor && (
          <PieceOfInfo
            info={getInfo(activeBulk, 'flatsLength') + ' ' + wordEndings(getInfo(activeBulk, 'flatsLength'))}
          />
        )}
        {!floorId && (
          <span className={style.visualInfo__whitebox}>
            {getInfo(activeBulk, 'decoration') === 'whitebox' ? 'White Box' : getInfo(activeBulk, 'decoration')}
          </span>
        )}
      </div>
    </>
  );
}

export default VisualInfo;
