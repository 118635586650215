import { useEffect } from 'react';

export const useResponsiveStyles = (
  bottomLinksRef,
  infoRef,
  infoStyle,
  linksStyle,
  priceRef,
  priceStyle,
  activeFlat,
  roomsState,
) => {
  useEffect(() => {
    if (bottomLinksRef.current && infoRef.current) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = width / height;
      const scrollbar = document.querySelector('.c-scrollbar');
      if (scrollbar) {
        scrollbar.remove();
      }

      if (aspectRatio > 2.1) {
        bottomLinksRef.current.classList.add(linksStyle);
        infoRef.current.classList.add(infoStyle);

        if (activeFlat) priceRef?.current?.classList?.add(priceStyle);
      }
    }
  }, [bottomLinksRef, infoRef, linksStyle, infoStyle, priceRef, priceStyle, activeFlat, roomsState]);
};
