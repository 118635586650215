import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchVisual = createAsyncThunk('visualSlice/fetchVisual', async (number, thunkApi) => {
  try {
    const response = await axios.get(api.urlFlats);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const initialState = {
  visual: null,
  fetchError: false,
  activeQueue: null,
};

export const visualSlice = createSlice({
  name: 'visualSlice',
  initialState,
  reducers: {
    handleChangeActiveQueue: (state, action) => {
      state.activeQueue = action.payload;
    },
  },
  extraReducers: {
    [fetchVisual.pending]: (state, action) => {},
    [fetchVisual.fulfilled]: (state, action) => {
      state.visual = action.payload;
    },
    [fetchVisual.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { handleChangeActiveQueue } = visualSlice.actions;

export const visualSelector = state => state.visual.visual;
export const fetchErrorSelector = state => state.visual.fetchError;
export const activeQueueSelector = state => state.visual.activeQueue;

export default visualSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
