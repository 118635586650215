import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchVisualBulk = createAsyncThunk('visualBulkSlice/fetchVisualBulk', async thunkApi => {
  try {
    const response = await axios.get(api.urlFlats);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const initialState = {
  data: null,
  fetchError: false,
  activeBulk: null,
  bulkLock: false,
};

export const visualBulkSlice = createSlice({
  name: 'visualBulkSlice',
  initialState,
  reducers: {
    setActiveBulk: (state, action) => {
      state.activeBulk = action.payload;
    },
    setBulkLock: (state, action) => {
      state.bulkLock = action.payload;
    },
  },
  extraReducers: {
    [fetchVisualBulk.pending]: (state, action) => {
      console.log('квартира загружается');
    },
    [fetchVisualBulk.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [fetchVisualBulk.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { setActiveBulk, setBulkLock } = visualBulkSlice.actions;

export const dataSelector = state => state.visualBulk.data;
export const fetchErrorSelector = state => state.visualBulk.fetchError;
export const activeBulkSelector = state => state.visualBulk.activeBulk;
export const bulkLockSelecetor = state => state.visualBulk.bulkLock;

export default visualBulkSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
