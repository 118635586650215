import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineDevice,
  deviceSelector,
  orientationSelector,
  useragentSelector,
  vendorSelector,
  widthSelector,
} from '../../entities/UserSlice';

export function useUserService() {
  const dispatch = useDispatch();
  const device = useSelector(deviceSelector);
  const useragent = useSelector(useragentSelector);
  const orientation = useSelector(orientationSelector);
  const width = useSelector(widthSelector);
  const vendor = useSelector(vendorSelector);

  useEffect(() => {
    if (!width || !useragent || !device || !orientation || !vendor) {
      dispatch(
        defineDevice({
          width: window.innerWidth,
          userAgent: navigator.userAgent,
          vendor: navigator.vendor,
        }),
      );
    }
  }, [width, useragent, orientation, device, dispatch, vendor]);

  return {
    device,
    useragent,
    orientation,
    width,
    vendor,
  };
}
