import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchGallery = createAsyncThunk('gallerySlice/fetchGallery', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlGallery);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const initialState = {
  gallery: null,
  fetchError: false,
};

export const gallerySlice = createSlice({
  name: 'gallerySlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGallery.fulfilled]: (state, action) => {
      state.gallery = action.payload;
    },
    [fetchGallery.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const gallerySelector = state => state.galleryInfo.gallery;
export const fetchErrorSelector = state => state.galleryInfo.fetchError;

export default gallerySlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
