import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { RedPriceTooltip } from '../../../shared/red-price-tooltip/RedPriceTooltip';
import checkBenefitPrice from '../../../tools/_check_benefit_price';
import formatLongPrice from '../../../tools/_format-long-price';
import style from './VisualFlatPrice.module.scss';

function VisualFlatPrice({ visual, flatData, priceRef }) {
  const { floorId } = useParams();
  if (!flatData) return;
  const price = checkBenefitPrice(flatData[0]);

  return (
    <>
      {flatData[0].redPrice ? (
        <div className={style.priceDiscount} ref={priceRef}>
          <RedPriceTooltip text='Только при 100% оплате или ипотеке без субсидирования' someStyles={style.tooltip}>
            {' '}
            <p className={style.priceDiscount__amount}>
              {formatLongPrice(flatData[0]?.currentPrice)} <span>₽</span>
            </p>
            <div className={style.priceDiscount__discount}>
              <p className={style.priceDiscount__discount__text}>
                {formatLongPrice(price)} <span>₽</span>
              </p>
              {!isMobile && <p>%</p>}
            </div>
          </RedPriceTooltip>
        </div>
      ) : (
        <p className={style.price} ref={priceRef}>
          {flatData[0].price} ₽
        </p>
      )}
    </>
  );
}

export default VisualFlatPrice;
