import icon1 from '../../assets/images/architecture_images/icon1.svg';
import icon2 from '../../assets/images/architecture_images/icon2.svg';
import icon3 from '../../assets/images/architecture_images/icon3.svg';
import icon4 from '../../assets/images/architecture_images/icon4.svg';
import icon5 from '../../assets/images/architecture_images/icon5.svg';
import icon6 from '../../assets/images/architecture_images/icon6.svg';
import icon7 from '../../assets/images/architecture_images/icon7.svg';
import icon8 from '../../assets/images/architecture_images/icon8.svg';
import one_Jpg from '../../assets/images/infrastructure_images/Infra_Desctop_Jpg/9.jpg';
import two_Jpg from '../../assets/images/infrastructure_images/Infra_Desctop_Jpg/10.jpg';
import {
  default as three_Jpg,
  default as three_Mob_Jpg,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Jpg/11.jpg';
import {
  default as four_Jpg,
  default as four_Mob_Jpg,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Jpg/12.jpg';
import {
  default as five_Jpg,
  default as five_Mob_Jpg,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Jpg/13.jpg';
import one_Webp from '../../assets/images/infrastructure_images/Infra_Desctop_Webp/9.webp';
import two_Webp from '../../assets/images/infrastructure_images/Infra_Desctop_Webp/10.webp';
import {
  default as three_Mob_Webp,
  default as three_Webp,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Webp/11.webp';
import {
  default as four_Mob_Webp,
  default as four_Webp,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Webp/12.webp';
import {
  default as five_Mob_Webp,
  default as five_Webp,
} from '../../assets/images/infrastructure_images/Infra_Desctop_Webp/13.webp';
import one_Mob_Webp from '../../assets/images/infrastructure_images/Infra_Mobile_Webp/9.webp';
import two_Mob_Webp from '../../assets/images/infrastructure_images/Infra_Mobile_Webp/10.webp';
import one_Mob_Jpg from '../../assets/images/infrastructure_images/Infra_Moble_Jpg/9.jpg';
import two_Mob_Jpg from '../../assets/images/infrastructure_images/Infra_Moble_Jpg/10.jpg';
import location_1_jpg from '../../assets/images/location_images/slider-1.jpg';
import location_1_webp from '../../assets/images/location_images/slider-1.webp';
import location_1_mob_jpg from '../../assets/images/location_images/slider-1-mob.jpg';
import location_1_mob_webp from '../../assets/images/location_images/slider-1-mob.webp';
import location_2_jpg from '../../assets/images/location_images/slider-2.jpg';
import location_2_webp from '../../assets/images/location_images/slider-2.webp';
import location_2_mob_jpg from '../../assets/images/location_images/slider-2-mob.jpg';
import location_2_mob_webp from '../../assets/images/location_images/slider-2-mob.webp';
import location_3_jpg from '../../assets/images/location_images/slider-3.jpg';
import location_3_webp from '../../assets/images/location_images/slider-3.webp';
import location_3_mob_jpg from '../../assets/images/location_images/slider-3-mob.jpg';
import location_3_mob_webp from '../../assets/images/location_images/slider-3-mob.webp';
import location_4_jpg from '../../assets/images/location_images/slider-4.jpg';
import location_4_webp from '../../assets/images/location_images/slider-4.webp';
import location_4_mob_jpg from '../../assets/images/location_images/slider-4-mob.jpg';
import location_4_mob_webp from '../../assets/images/location_images/slider-4-mob.webp';
import location_5_jpg from '../../assets/images/location_images/slider-5.jpg';
import location_5_webp from '../../assets/images/location_images/slider-5.webp';
import location_5_mob_jpg from '../../assets/images/location_images/slider-5-mob.jpg';
import location_5_mob_webp from '../../assets/images/location_images/slider-5-mob.webp';
import location_6_jpg from '../../assets/images/location_images/slider-6.jpg';
import location_6_webp from '../../assets/images/location_images/slider-6.webp';
import location_6_mob_jpg from '../../assets/images/location_images/slider-6-mob.jpg';
import location_6_mob_webp from '../../assets/images/location_images/slider-6-mob.webp';
import location_7_jpg from '../../assets/images/location_images/slider-7.jpg';
import location_7_webp from '../../assets/images/location_images/slider-7.webp';
import location_7_mob_jpg from '../../assets/images/location_images/slider-7-mob.jpg';
import location_7_mob_webp from '../../assets/images/location_images/slider-7-mob.webp';
import img1_jpg from '../../assets/images/scroll_images/scroll_1.jpg';
import img1 from '../../assets/images/scroll_images/scroll_1.webp';
import imgPlug1 from '../../assets/images/scroll_images/scroll_1_plug.jpg';
import img2_jpg from '../../assets/images/scroll_images/scroll_2.jpg';
import img2 from '../../assets/images/scroll_images/scroll_2.webp';
import imgPlug2 from '../../assets/images/scroll_images/scroll_2_plug.jpg';
import img3_jpg from '../../assets/images/scroll_images/scroll_3.jpg';
import img3 from '../../assets/images/scroll_images/scroll_3.webp';
import imgPlug3 from '../../assets/images/scroll_images/scroll_3_plug.jpg';
import img4_jpg from '../../assets/images/scroll_images/scroll_4.jpg';
import img4 from '../../assets/images/scroll_images/scroll_4.webp';
import imgPlug4 from '../../assets/images/scroll_images/scroll_4_plug.jpg';
import img5_jpg from '../../assets/images/scroll_images/scroll_5.jpg';
import img5 from '../../assets/images/scroll_images/scroll_5.webp';
import imgPlug5 from '../../assets/images/scroll_images/scroll_5_plug.jpg';
import img1_xsj from '../../assets/mobile_images_gen/scroll_images/scroll_1.jpg';
import img1_xs from '../../assets/mobile_images_gen/scroll_images/scroll_1.webp';
import img2_xsj from '../../assets/mobile_images_gen/scroll_images/scroll_2.jpg';
import img2_xs from '../../assets/mobile_images_gen/scroll_images/scroll_2.webp';
import img3_xsj from '../../assets/mobile_images_gen/scroll_images/scroll_3.jpg';
import img3_xs from '../../assets/mobile_images_gen/scroll_images/scroll_3.webp';
import img4_xsj from '../../assets/mobile_images_gen/scroll_images/scroll_4.jpg';
import img4_xs from '../../assets/mobile_images_gen/scroll_images/scroll_4.webp';
import img5_xsj from '../../assets/mobile_images_gen/scroll_images/scroll_5.jpg';
import img5_xs from '../../assets/mobile_images_gen/scroll_images/scroll_5.webp';

import healthActiveIcon from '../../assets/images/location_images/mapIcon/healthActive.svg';
import sportActiveIcon from '../../assets/images/location_images/mapIcon/sportActive.svg';
import shopsActiveIcon from '../../assets/images/location_images/mapIcon/shoppingActive.svg';
import educationActiveIcon from '../../assets/images/location_images/mapIcon/educationActive.svg';
import transportActiveIcon from '../../assets/images/location_images/mapIcon/transportActive.svg';
import cafeActiveIcon from '../../assets/images/location_images/mapIcon/cafeActive.svg';
import beautyActiveIcon from '../../assets/images/location_images/mapIcon/beautyActive.svg';
import parkActiveIcon from '../../assets/images/location_images/mapIcon/parkActive.svg';
import allActiveIcon from '../../assets/images/location_images/mapIcon/allActive.svg';

import healthIcon from '../../assets/images/location_images/mapIcon/health.svg';
import sportIcon from '../../assets/images/location_images/mapIcon/sport.svg';
import shopsIcon from '../../assets/images/location_images/mapIcon/shopping.svg';
import educationIcon from '../../assets/images/location_images/mapIcon/education.svg';
import transportIcon from '../../assets/images/location_images/mapIcon/transport.svg';
import cafeIcon from '../../assets/images/location_images/mapIcon/cafe.svg';
import beautyIcon from '../../assets/images/location_images/mapIcon/beauty.svg';
import parkIcon from '../../assets/images/location_images/mapIcon/park.svg';
import allIcon from '../../assets/images/location_images/mapIcon/all.svg';

import healthBlackIcon from '../../assets/images/location_images/mapIcon/healthBlack.svg';
import sportBlackIcon from '../../assets/images/location_images/mapIcon/sportBlack.svg';
import shopsBlackIcon from '../../assets/images/location_images/mapIcon/shoppingBlack.svg';
import educationBlackIcon from '../../assets/images/location_images/mapIcon/educationBlack.svg';
import transportBlackIcon from '../../assets/images/location_images/mapIcon/transportBlack.svg';
import cafeBlackIcon from '../../assets/images/location_images/mapIcon/cafeBlack.svg';
import beautyBlackIcon from '../../assets/images/location_images/mapIcon/beautyBlack.svg';
import parkBlackIcon from '../../assets/images/location_images/mapIcon/parkBlack.svg';

export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const routesGeneralPage = [
  {
    name: 'О проекте',
    route: '#about',
    routeLink: 'about',
  },
  {
    name: 'Расположение',
    route: '#location',
    routeLink: 'location',
  },
  {
    name: 'Архитектура',
    route: '#architecture',
    routeLink: 'architecture',
  },
  {
    name: 'Планировки',
    route: '#plans',
    routeLink: 'plans',
  },
  {
    name: 'Благоустройство',
    route: '#improvement',
    routeLink: 'improvement',
  },
  {
    name: 'Девелопер',
    route: '#developer',
    routeLink: 'developer',
  },
];

export const scrollInfo = [
  {
    text: '<p>Атмосфера</p> <p>загородного</p> <p>отдыха</p>',
    src: `${img1_jpg}`,
    srcWebP: `${img1}`,
    srcXS: `${img1_xs}`,
    srcXS_j: `${img1_xsj}`,
    rotate: 0.82,
    top: 5,
    plug: imgPlug1,
  },
  {
    text: '<p>Жизнь</p> <p>рядом</p> <p>с Красной книгой</p>',
    src: `${img2_jpg}`,
    srcWebP: `${img2}`,
    srcXS: `${img2_xs}`,
    srcXS_j: `${img2_xsj}`,
    rotate: -2.42,
    top: 0,
    plug: imgPlug2,
  },
  {
    text: '<p>Особый</p> <p>лайфстайл</p>',
    src: `${img3_jpg}`,
    srcWebP: `${img3}`,
    srcXS: `${img3_xs}`,
    srcXS_j: `${img3_xsj}`,
    rotate: 0.1,
    top: 5,
    plug: imgPlug3,
  },
  {
    text: '<p>На берегу</p> <p>зелёного</p> <p>океана</p>',
    src: `${img4_jpg}`,
    srcWebP: `${img4}`,
    srcXS: `${img4_xs}`,
    srcXS_j: `${img4_xsj}`,
    rotate: -1.16,
    top: 0,
    plug: imgPlug4,
  },

  {
    text: '<p>Всё важное</p>  <p>рядом</p>',
    src: `${img5_jpg}`,
    srcWebP: `${img5}`,
    srcXS: `${img5_xs}`,
    srcXS_j: `${img5_xsj}`,
    rotate: 1.29,
    top: 5,
    plug: imgPlug5,
  },
];

export const roomsInfo = [
  { title: 'студии', area: '40,95', active: true },
  { title: '1-комнатные', area: '50,95', active: false },
  { title: '2-комнатные', area: '60,95', active: false },
  { title: '3-комнатные', area: '70,95', active: false },
  { title: '4-комнатные', area: '80,95', active: false },
];

export const infoAboutProject = [
  {
    id: 1,
    title: 5,
    info: 'пешком до&nbsp;МЦК &laquo;Стрешнево&raquo;',
    infoMob: 'пешком до<br/>МЦК &laquo;Стрешнево&raquo;',
  },
  {
    id: 2,
    title: 15,
    info: 'пешком до&nbsp;ж/д станции &laquo;Стрешнево&raquo; (D2)',
    infoMob: 'пешком до&nbsp;ж/д станции<br/>&laquo;Стрешнево&raquo; (D2)',
  },
  {
    id: 3,
    title: 15,
    info: 'на&nbsp;автомобиле до&nbsp;Кремля',
    infoMob: 'на&nbsp;автомобиле<br/>до&nbsp;Кремля',
  },
  {
    id: 4,
    title: 11,
    info: 'на&nbsp;автомобиле до&nbsp;Белорусского вокзала',
    infoMob: 'на&nbsp;автомобиле до<br/>Белорусского&nbsp;вокзала',
  },
];

export const infoAboutLandscaping = [
  { id: 1, title: '2', info: 'Внутренних двора' },
  {
    id: 2,
    title: '1000 м',
    info: `Длина экотрека в&nbsp;парке </br>Покровское-Стрешнево`,
  },

  { id: 3, title: '100+', info: 'Видов озеленения' },
];

export const scrollInfrastructure = [
  {
    text: '<p>Атмосфера</p> <p>загородного</p> <p>отдыха</p>',
    src: `${one_Jpg}`,
    srcWebP: `${one_Webp}`,
    srcXS: `${one_Mob_Webp}`,
    srcXS_j: `${one_Mob_Jpg}`,
    rotate: -7.96,
    top: 0,
    //height: 648,
    id: 1,
  },
  {
    text: '<p>Понежиться</p> <p> в шезлонге</p>',
    src: `${two_Jpg}`,
    srcWebP: `${two_Webp}`,
    srcXS: `${two_Mob_Webp}`,
    srcXS_j: `${two_Mob_Jpg}`,
    rotate: -2.97,
    top: 5,
    //height: 626,
    id: 2,
  },
  {
    text: '<p>Устроить</p> <p>пикник</p>',
    src: `${three_Jpg}`,
    srcWebP: `${three_Webp}`,
    srcXS: `${three_Mob_Webp}`,
    srcXS_j: `${three_Mob_Jpg}`,
    rotate: 0.1,
    top: -1,
    //height: 612,
    id: 3,
  },
  {
    text: '<p>Сыграть</p> <p>партию</p> <p>в шахматы</p>',
    src: `${four_Jpg}`,
    srcWebP: `${four_Webp}`,
    srcXS: `${four_Mob_Webp}`,
    srcXS_j: `${four_Mob_Jpg}`,
    rotate: -2.04,
    top: 2,
    //height: 660,
    id: 4,
  },

  {
    text: '<p>Иммерсивное</p>  <p>благоустройство</p>',
    src: `${five_Jpg}`,
    srcWebP: `${five_Webp}`,
    srcXS: `${five_Mob_Webp}`,
    srcXS_j: `${five_Mob_Jpg}`,
    rotate: 1,
    top: -3,
    //height: 663,
    id: 5,
  },
];

export const scrollLocation = [
  {
    text: '<p>Парк Покровское-</p> <p> Стрешнево</p>',
    src: `${location_1_jpg}`,
    srcWebP: `${location_1_webp}`,
    srcXS: `${location_1_mob_webp}`,
    srcXS_j: `${location_1_mob_jpg}`,
    rotate: -7.96,
    top: 0,
    //height: 648,
    id: 1,
  },
  {
    text: '<p>Торговый центр</p> <p> Метрополис</p>',
    src: `${location_2_jpg}`,
    srcWebP: `${location_2_webp}`,
    srcXS: `${location_2_mob_webp}`,
    srcXS_j: `${location_2_mob_jpg}`,
    rotate: -2.97,
    top: 5,
    //height: 626,
    id: 2,
  },
  {
    text: '<p>Усадьба Покровское-</p> <p> Стрешнево</p>',
    src: `${location_3_jpg}`,
    srcWebP: `${location_3_webp}`,
    srcXS: `${location_3_mob_webp}`,
    srcXS_j: `${location_3_mob_jpg}`,
    rotate: 0.1,
    top: -1,
    //height: 612,
    id: 3,
  },
  {
    text: '<p>Стадион</p> <p> &laquo;Открытие Арена&raquo;</p>',
    src: `${location_4_jpg}`,
    srcWebP: `${location_4_webp}`,
    srcXS: `${location_4_mob_webp}`,
    srcXS_j: `${location_4_mob_jpg}`,
    rotate: -2.04,
    top: 2,
    //height: 660,
    id: 4,
  },

  {
    text: '<p>Фитнес-центр</p>  <p> Marina Club</p>',
    src: `${location_5_jpg}`,
    srcWebP: `${location_5_webp}`,
    srcXS: `${location_5_mob_webp}`,
    srcXS_j: `${location_5_mob_jpg}`,
    rotate: 1,
    top: -3,
    //height: 663,
    id: 5,
  },

  {
    text: '<p>Щукинский</p>  <p> парк</p>',
    src: `${location_6_jpg}`,
    srcWebP: `${location_6_webp}`,
    srcXS: `${location_6_mob_webp}`,
    srcXS_j: `${location_6_mob_jpg}`,
    rotate: -3,
    top: 3,
    //height: 663,
    id: 6,
  },

  {
    text: '<p>Всехсвятская</p>  <p> роща</p>',

    src: `${location_7_jpg}`,
    srcWebP: `${location_7_webp}`,
    srcXS: `${location_7_mob_webp}`,
    srcXS_j: `${location_7_mob_jpg}`,
    rotate: 2,
    top: -3,
    //height: 663,
    id: 7,
  },
];

export const mapPins = [
  {
    title: 'health',
    values: [
      {
        coordinates: [55.813786, 37.480315],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Центр оториноларингологии',
        category: 'health',
      },
      {
        coordinates: [55.803401, 37.488362],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Поликлиника № 115',
        category: 'health',
      },
      {
        coordinates: [55.816438, 37.506895],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Детская городская поликлиника № 133',
        category: 'health',
      },
      {
        coordinates: [55.818528, 37.502432],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Детская городская поликлиника № 15',
        category: 'health',
      },
      {
        coordinates: [55.803397, 37.496545],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Детская городская поликлиника № 39',
        category: 'health',
      },
      {
        coordinates: [55.806172, 37.455762],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Детская городская поликлиника № 58',
        category: 'health',
      },
      {
        coordinates: [55.819312, 37.466639],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Лечебно-реабилитационный центр',
        category: 'health',
      },
      {
        coordinates: [55.818411, 37.494882],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Городская поликлиника № 45',
        category: 'health',
      },
      {
        coordinates: [55.811946, 37.497552],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Стоматологическая поликлиника',
        category: 'health',
      },
      {
        coordinates: [55.803251, 37.487811],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Консультативно-диагностический центр',
        category: 'health',
      },
      {
        coordinates: [55.813211, 37.465571],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Поликлиническое отделение',
        category: 'health',
      },
      {
        coordinates: [55.805762, 37.45854],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Центр урологии',
        category: 'health',
      },
      {
        coordinates: [55.804885, 37.462563],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Городская поликлиника № 115',
        category: 'health',
      },
      {
        coordinates: [55.823224, 37.503108],
        icon: healthActiveIcon,
        blackIcon: healthBlackIcon,
        title: 'Городская онкологическая больница № 62',
        category: 'health',
      },
    ],
  },
  {
    title: 'sport',
    values: [
      {
        coordinates: [55.820359, 37.465776],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'First&Only',
        category: 'sport',
      },
      {
        coordinates: [55.827158, 37.478488],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'Marina Club',
        category: 'sport',
      },
      {
        coordinates: [55.82386, 37.496025],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'World Class',
        category: 'sport',
      },
      {
        coordinates: [55.824109, 37.500058],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'Spirit. Fitness',
        category: 'sport',
      },
      {
        coordinates: [55.80918, 37.512323],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'Powerhouse Gym',
        category: 'sport',
      },
      {
        coordinates: [55.823922, 37.518016],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'Gym-Gym',
        category: 'sport',
      },
      {
        coordinates: [55.801569, 37.470983],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'EasyFitness',
        category: 'sport',
      },
      {
        coordinates: [55.809749, 37.464654],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'DDX FITNESS',
        category: 'sport',
      },
      {
        coordinates: [55.809742, 37.459753],
        icon: sportActiveIcon,
        blackIcon: sportBlackIcon,
        title: 'Spirit. Fitness',
        category: 'sport',
      },
    ],
  },
  {
    title: 'shopping',
    values: [
      {
        coordinates: [55.809462, 37.463943],
        icon: shopsActiveIcon,
        blackIcon: shopsBlackIcon,
        title: 'Щука',
        category: 'shopping',
      },
      {
        coordinates: [55.799804, 37.482988],
        icon: shopsActiveIcon,
        blackIcon: shopsBlackIcon,
        title: '5 авеню',
        category: 'shopping',
      },
      {
        coordinates: [55.790491, 37.531373],
        icon: shopsActiveIcon,
        blackIcon: shopsBlackIcon,
        title: 'Авиапарк',
        category: 'shopping',
      },
      {
        coordinates: [55.801765, 37.531894],
        icon: shopsActiveIcon,
        blackIcon: shopsBlackIcon,
        title: 'Галерея Аэропорт',
        category: 'shopping',
      },
      {
        coordinates: [55.823492, 37.497697],
        icon: shopsActiveIcon,
        blackIcon: shopsBlackIcon,
        title: 'Метрополис',
        category: 'shopping',
      },
    ],
  },
  {
    title: 'education',
    values: [
      {
        coordinates: [55.813827, 37.45913],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 830',
        category: 'education',
      },
      {
        coordinates: [55.80705, 37.452319],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1210',
        category: 'education',
      },
      {
        coordinates: [55.805177, 37.467442],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1874',
        category: 'education',
      },
      {
        coordinates: [55.805044, 37.469902],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Курчатовская школа',
        category: 'education',
      },
      {
        coordinates: [55.804854, 37.471136],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Курчатовская школа',
        category: 'education',
      },
      {
        coordinates: [55.805348, 37.482519],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Курчатовская школа',
        category: 'education',
      },
      {
        coordinates: [55.805871, 37.5002],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 149',
        category: 'education',
      },
      {
        coordinates: [55.80923, 37.496151],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1252',
        category: 'education',
      },
      {
        coordinates: [55.808412, 37.501395],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1252',
        category: 'education',
      },
      {
        coordinates: [55.809914, 37.496431],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1252',
        category: 'education',
      },
      {
        coordinates: [55.817583, 37.493226],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 2099',
        category: 'education',
      },
      {
        coordinates: [55.817439, 37.504923],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 717',
        category: 'education',
      },
      {
        coordinates: [55.819504, 37.500866],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 201',
        category: 'education',
      },
      {
        coordinates: [55.822026, 37.504244],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 90',
        category: 'education',
      },
      {
        coordinates: [55.819634, 37.511335],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 717',
        category: 'education',
      },
      {
        coordinates: [55.821427, 37.504318],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 201',
        category: 'education',
      },
      {
        coordinates: [55.815109, 37.462456],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Академическая гимназия, детский сад',
        category: 'education',
      },
      {
        coordinates: [55.813827, 37.45913],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 830',
        category: 'education',
      },
      {
        coordinates: [55.806848, 37.460873],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1874',
        category: 'education',
      },
      {
        coordinates: [55.803331, 37.468407],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1874',
        category: 'education',
      },
      {
        coordinates: [55.809649, 37.474002],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Курчатовская школа',
        category: 'education',
      },
      {
        coordinates: [55.80667, 37.482686],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Ювенес',
        category: 'education',
      },
      {
        coordinates: [55.809056, 37.474119],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Курчатовская школа',
        category: 'education',
      },
      {
        coordinates: [55.803833, 37.49011],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Мандарин',
        category: 'education',
      },
      {
        coordinates: [55.809096, 37.504266],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 149',
        category: 'education',
      },
      {
        coordinates: [55.809435, 37.497046],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1252',
        category: 'education',
      },
      {
        coordinates: [55.810494, 37.493923],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Детские ясли № 642',
        category: 'education',
      },
      {
        coordinates: [55.813701, 37.493451],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 1252',
        category: 'education',
      },
      {
        coordinates: [55.817221, 37.487851],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Белый мишка',
        category: 'education',
      },
      {
        coordinates: [55.816234, 37.496326],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Лаборатория мехатроники и робототехники',
        category: 'education',
      },
      {
        coordinates: [55.816716, 37.491414],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 2099',
        category: 'education',
      },
      {
        coordinates: [55.817375, 37.489959],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 2099',
        category: 'education',
      },
      {
        coordinates: [55.816604, 37.491318],
        icon: educationActiveIcon,
        blackIcon: educationBlackIcon,
        title: 'Школа № 2099',
        category: 'education',
      },
    ],
  },
  {
    title: 'transport',
    values: [
      {
        coordinates: [55.812189, 37.486073],
        icon: transportActiveIcon,
        blackIcon: transportBlackIcon,
        title: 'МЦК Стрешнево',
        category: 'transport',
      },
      {
        coordinates: [55.815139, 37.487141],
        icon: transportActiveIcon,
        blackIcon: transportBlackIcon,
        title: 'МЦД-2 Стрешнево',
        category: 'transport',
      },
      {
        coordinates: [55.811673, 37.482591],
        icon: transportActiveIcon,
        blackIcon: transportBlackIcon,
        title: 'Автобусная остановка "Пехотная улица"',
        category: 'transport',
      },
    ],
  },
  {
    title: 'cafe',
    values: [
      {
        coordinates: [55.812753, 37.479723],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Кафетерий № 1',
        category: 'cafe',
      },
      {
        coordinates: [55.809997, 37.483283],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Д-кафе',
        category: 'cafe',
      },
      {
        coordinates: [55.809807, 37.476562],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Загородный',
        category: 'cafe',
      },
      {
        coordinates: [55.810668, 37.47636],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Я Самарканд',
        category: 'cafe',
      },
      {
        coordinates: [55.81661, 37.460146],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Бигсуши',
        category: 'cafe',
      },
      {
        coordinates: [55.809176, 37.463678],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Колбасофф',
        category: 'cafe',
      },
      {
        coordinates: [55.809388, 37.460437],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Якитория',
        category: 'cafe',
      },
      {
        coordinates: [55.80616, 37.461594],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Старый Батумъ',
        category: 'cafe',
      },
      {
        coordinates: [55.810055, 37.453163],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Пивотека 465',
        category: 'cafe',
      },
      {
        coordinates: [55.826628, 37.4447],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Kultura',
        category: 'cafe',
      },
      {
        coordinates: [55.816723, 37.500501],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Wildschwein',
        category: 'cafe',
      },
      {
        coordinates: [55.816566, 37.501779],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Bizone Lounge',
        category: 'cafe',
      },
      {
        coordinates: [55.823233, 37.495769],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Урюк',
        category: 'cafe',
      },
      {
        coordinates: [55.820501, 37.500883],
        icon: cafeActiveIcon,
        blackIcon: cafeBlackIcon,
        title: 'Ача-чача',
        category: 'cafe',
      },
    ],
  },
  {
    title: 'beauty',
    values: [
      {
        coordinates: [55.823846, 37.496418],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Wow Brow',
        category: 'beauty',
      },
      {
        coordinates: [55.823543, 37.500417],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Fashion place',
        category: 'beauty',
      },
      {
        coordinates: [55.820743, 37.508466],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Выбор Красоты',
        category: 'beauty',
      },
      {
        coordinates: [55.819765, 37.494346],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Салон красоты Lime',
        category: 'beauty',
      },
      {
        coordinates: [55.811207, 37.497939],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'АртКреатив',
        category: 'beauty',
      },
      {
        coordinates: [55.808681, 37.498295],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Chi Studio',
        category: 'beauty',
      },
      {
        coordinates: [55.809202, 37.48349],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Салон красоты БонЖур',
        category: 'beauty',
      },
      {
        coordinates: [55.816226, 37.461584],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Robiar',
        category: 'beauty',
      },
      {
        coordinates: [55.809684, 37.463501],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'Воображуля',
        category: 'beauty',
      },
      {
        coordinates: [55.807695, 37.461913],
        icon: beautyActiveIcon,
        blackIcon: beautyBlackIcon,
        title: 'In White',
        category: 'beauty',
      },
    ],
  },
  {
    title: 'park',
    values: [
      {
        coordinates: [55.829051, 37.428791],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Берег реки Сходня',
        category: 'park',
      },
      {
        coordinates: [55.810554, 37.479783],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Всехсвятская роща',
        category: 'park',
      },
      {
        coordinates: [55.822112, 37.476377],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Покровское-Стрешнево',
        category: 'park',
      },
      {
        coordinates: [55.830561, 37.484706],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Берёзовый парк',
        category: 'park',
      },
      {
        coordinates: [55.832759, 37.472286],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Парк Покровский берег',
        category: 'park',
      },
      {
        coordinates: [55.803607, 37.474193],
        icon: parkActiveIcon,
        blackIcon: parkBlackIcon,
        title: 'Щукинский парк',
        category: 'park',
      },
    ],
  },
];

export const mapFilters = [
  {
    id: 'all',
    title: 'Все объекты',
    icon: allIcon,
    iconActive: allActiveIcon,
  },
  {
    id: 'shopping',
    title: 'Шопинг',
    icon: shopsIcon,
    iconActive: shopsActiveIcon,
  },
  {
    id: 'education',
    title: 'Образование',
    icon: educationIcon,
    iconActive: educationActiveIcon,
  },
  {
    id: 'health',
    title: 'Здоровье',
    icon: healthIcon,
    iconActive: healthActiveIcon,
  },
  {
    id: 'sport',
    title: 'Спорт',
    icon: sportIcon,
    iconActive: sportActiveIcon,
  },
  {
    id: 'transport',
    title: 'Транспорт',
    icon: transportIcon,
    iconActive: transportActiveIcon,
  },
  {
    id: 'cafe',
    title: 'Кафе и рестораны',
    icon: cafeIcon,
    iconActive: cafeActiveIcon,
  },
  {
    id: 'beauty',
    title: 'Салон красоты',
    icon: beautyIcon,
    iconActive: beautyActiveIcon,
  },
  {
    id: 'park',
    title: 'Парки и скверы',
    icon: parkIcon,
    iconActive: parkActiveIcon,
  },
];
