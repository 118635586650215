import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchDocuments = createAsyncThunk('documentsSlice/fetchDocuments', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlDocuments);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const initialState = {
  documents: null,
  fetchError: false,
};

export const documentsSlice = createSlice({
  name: 'documentsInfo',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload;
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const documentsSelector = state => state.documentsInfo.documents;

export const fetchErrorSelector = state => state.documentsInfo.fetchError;

export default documentsSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
