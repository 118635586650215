import cn from 'classnames';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tagMob from '../../../assets/images/visual_images/tagMob.svg';
import { setBottomSheetIsOpen, sheetIsOpenSelector } from '../../../entities/VisualFloorSlice';
import BlurTooltip from '../../../shared/blur-tooltip/BlurTooltip';
import BackLink from '../VisualBottomLinks/BackLink/BackLink';
import style from './BottomSheet.module.scss';

function BottomSheet(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [startY, setStartY] = useState(null);
  const { floorId, houseId, bulkId } = useParams();

  const dispatch = useDispatch();

  const sheetIsOpen = useSelector(sheetIsOpenSelector);

  const handleTouchStart = e => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = e => {
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;

    if (deltaY < 0) {
      setIsOpen(true);
      dispatch(setBottomSheetIsOpen(true));
    } else {
      setIsOpen(false);
      dispatch(setBottomSheetIsOpen(false));
    }
  };

  const sheetOnRoot = !isOpen ? style.sheetForRoot : style.openSheet;

  const sheetOnFloor =
    !isOpen && props.activeForm
      ? style.sheetForFloor
      : !isOpen && !props.activeForm
      ? style.sheetForFloor
      : isOpen && props.activeForm
      ? style.openSheet
      : isOpen && !props.activeForm
      ? style.openSheetForm
      : null;
  const sheetOnFlat = !isOpen ? style.sheetForFlat : style.flatSheetOpen;

  useEffect(() => {
    if (sheetIsOpen) {
      setIsOpen(true);
    }
  }, [sheetIsOpen]);

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      className={cn(
        `${floorId ? style.flatWrapper : style.wrapper}`,
        `${!houseId ? sheetOnRoot : houseId && !floorId ? sheetOnFloor : sheetOnFlat}`,
      )}
    >
      <BackLink />

      {isMobileOnly && !houseId && (
        <BlurTooltip someStyles={style.tagMob}>
          <SVG src={tagMob} />
        </BlurTooltip>
      )}

      {isMobileOnly && houseId && !bulkId && (
        <BlurTooltip someStyles={style.tagMob}>
          <SVG src={tagMob} />
        </BlurTooltip>
      )}

      <div className={style.children}>
        <div className={style.toggler} />
        {props.children}
      </div>
    </div>
  );
}

export default BottomSheet;
