import { DOMAIN } from '../tools/helpers/constants';

const api = {
  urlFlats: process.env.REACT_APP_MANAGERFORMA_API_FUll,
  urlAttributes: `${process.env.REACT_APP_MANAGER_URL}/api/lot-attributes/list`,
  urlBulks: `${process.env.REACT_APP_MANAGER_URL}/api/bulks?blockId=1457`,
  urlPdf: `${process.env.REACT_APP_ADMIN_FORMA}`,

  //Страница "Условия покупки", относительные пути апи работают через прокси (setupProxy.js)
  urlNavList: `${DOMAIN}/api/menu/purchase`,
  urlTradeIn: `${DOMAIN}/api/special_program/trade-in`,
  urlQa: `${DOMAIN}/api/qa`,
  urlSpecialMortgages: `${DOMAIN}/api/special_program/mortgage`,
  urlMortgages: `${DOMAIN}/api/special_program/mortgage-standard`,
  urlCalcData: `${DOMAIN}/api/mortgages`,
  urlInstallments: `${DOMAIN}/api/special_program/installment`,
  urlSpecialInstallments: `${DOMAIN}/api/special_program/installment-main`,
  urlCash: `${DOMAIN}/api/special_program/cash`,
  urlCashback: `${DOMAIN}/api/special_program/cashback`,
  urlPromoCards: `${DOMAIN}/api/promo_cards`,

  urlGeneralSettings: `${DOMAIN}/api/general_settings`,

  // Страница "Документы"
  urlDocuments: `${DOMAIN}/api/documents`,

  // Страница "Школа"
  urlSchool: `${DOMAIN}/api/single-page/school`,

  // Страница "Новости"
  urlNews: `${DOMAIN}/api/news`,

  // Страница "Ход строительства"
  urlProgress: `${DOMAIN}/api/progress`,
  urlProgressCompareSlider: `${DOMAIN}/api/slider/now_future`,

  // Страница 'Архитектура'
  urlArchitecture: `${DOMAIN}/api/slider/architecture`,

  // Страница 'Галерея'
  urlGallery: `${DOMAIN}/api/gallery`,

  urlVisual: `/api/visual`,

  //Меню
  urlMenuLeft: `${DOMAIN}/api/menu/burger-left`,
  urlMenuRight: `${DOMAIN}/api/menu/burger-right`,
  urlFooterLeft: `${DOMAIN}/api/menu/footer-left`,
  urlFooterRight: `${DOMAIN}/api/menu/footer-right`,
  urlFooterMiddle: `${DOMAIN}/api/menu/footer-middle`,
};

export default api;
