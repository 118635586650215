import IconsSVG from '../../assets/svg/IconsSVG.svg';

const SVGelem = ({ className, href, handleClick }) => {
  return (
    <>
      {IconsSVG && handleClick && (
        <svg onClick={() => handleClick()} className={className}>
          <use href={IconsSVG + `${href}`} />
        </svg>
      )}
      {IconsSVG && !handleClick && (
        <svg className={className}>
          <use href={IconsSVG + `${href}`} />
        </svg>
      )}
    </>
  );
};

export default SVGelem;
