import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import booleanSwitch from '../tools/_boolean-switch';
import calcTheLargest from '../tools/_calc-the-largest';
import calcTheLeast from '../tools/_calc-the-least';
import changeStudioValue from '../tools/_change-studio-value';
import checkParameterAndToggleStatus from '../tools/_check-parameter-and-toggle-status';
import editPriceInMillions from '../tools/_edit-price-in-millions';
import getSelectedParams from '../tools/_get-selected-params';
import resetSortingParameter from '../tools/_reset-sorting-parameter';
import sortNumber from '../tools/_sort-number';
import api from './api';

export const fetchFlats = createAsyncThunk('flatsSlice/fetchFlats', async () => {
  const response = await axios.get(api.urlFlats, {});
  return response.data;
});

export const fetchFlat = createAsyncThunk('flatsSlice/fetchFlat', async (number, thunkApi) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_MANAGER_URL}/api/v2/flat/${number}?blockId=1457`);
    return response.data;
  } catch (error) {
    return console.log(error, thunkApi);
  }
});

export const fetchAttributes = createAsyncThunk('flatsSlice/fetchAttributes', async () => {
  const response = await axios.get(api.urlAttributes);
  return response.data.data;
});

const initialState = {
  urls: {
    getResidential: {
      url: api.urlFlats,
      initial: false,
      status: null,
      error: null,
    },
  },
  filterParameters: {
    byCheckbox: {
      redPrice: [{ name: 'Квартира по акции', value: true, active: false }],
      rooms: [
        { name: 'Любоe', value: 'Любой', active: false, disabled: false },
        { name: 'СТ', value: '0', active: false, disabled: false },
        { name: '1', value: '1', active: false, disabled: false },
        { name: '2', value: '2', active: false, disabled: false },
        { name: '3', value: '3', active: false, disabled: false },
        { name: '4', value: '4', active: false, disabled: false },
        { name: '5', value: '5', active: false, disabled: false },
      ],
      sections: [
        { name: 'Любой', value: 'Любой', active: false, disabled: false },
        { name: '1', value: '1', active: false, disabled: false },
        { name: '2.1', value: '2.1', active: false, disabled: false },
        { name: '2.3', value: '2.3', active: false, disabled: false },
      ],
      deadline: [
        { name: 'Любой', value: 'Любой', active: false },
        { name: 'III кв 2025', value: 'III кв 2025', active: false },
        { name: 'IV кв 2025', value: 'IV кв 2025', active: false },
        { name: 'I кв 2028', value: 'I кв 2028', active: false },
      ],
      quarter: [
        { name: 'Любой', value: 'Любой', active: false },
        {
          name: 'Квартал Некрасовка',
          value: 'Квартал Некрасовка',
          active: false,
        },
      ],
      advantages: null,
    },
    selectedParams: {},

    //Значения ползунков в фильтре по умолчанию
    initialValues: {
      area: [0, 10],
      price: [0, 10],
      floor: [0, 10],
    },
    //Возможные параметры фильтрации
    params: ['floor', 'price', 'area'],
    //Параметры, которые были выбраны пользователем
    interactedParams: {
      floor: {
        min: false,
        max: false,
      },
      price: {
        min: false,
        max: false,
      },
      area: {
        min: false,
        max: false,
      },
    },
    //Выбранные параметры для отображения на странице в кнопках сброса
    ableToResetParams: {
      sections: [],
      rooms: [],
      floor: [],
      area: [],
      price: [],
      advantages: [],
    },
    inactiveSliders: {
      floor: false,
      price: false,
      area: false,
    },
    //Текущие значения слайдеров
    slidersValues: {
      area: [25, 163],
      price: [12000000, 86000000],
      floor: [1, 30],
    },
    //Крайние значения слайдеров
    slidersExtremeValues: {
      area: [25, 163],
      price: [1200000, 86000000],
      floor: [1, 30],
    },
    //Значения инпутов
    inputsValues: {
      area: [25, 163],
      price: [12000000, 86000000],
      floor: [1, 30],
    },
    amountOfSelectedParams: 0,
  },
  //Колонки таблицы для сортировки
  sortColumns: {
    scheme: 'Планировка',
    rooms: 'Cпален',
    number: 'Номер',
    area: 'Площадь',
    sections_number: 'Корпус',
    floor: 'Этаж',
    fav: 'Срок сдачи',
    real_price: 'Cтоимость',
    advantages: 'Особенности',
  },
  selectedСategories: [],
  //Текущий параметр сортировки
  sortParameters: {
    value: 'area',
    direction: 'asc_',
  },
  //Места вызова функции
  //по клику на заголовок столбца таблицы
  inTableHead: 'inTableHead',
  //после изменения состояния формы
  inForm: 'inForm',
  //Квартиры, которые выводятся на страницу
  flat: {},
  flatInfo: {},
  flats: [],
  //Все квартиры, взятые с сервера
  allFlats: [],
  maxFloor: '',
  //Квартиры после фильтрации чекбоксами
  filteredByCheckboxFlats: [],
  //Отфильтрован список квартир?
  isFiltered: false,
  countFilteres: [],
  //Выбранная квартира
  currentFlat: '',
  apartmentNumber: '',
  //Статус квартиры
  flatStatus: {
    free: 'free',
    reserve: 'reserve',
  },
  //Список специальных условий
  benefits: [],
  //Активное спец.условие
  activeBenefit: {},
  filtersSelect: {
    selectAmountRoom: 'Любой',
    selectFrame: 'Любой',
    selectRent: 'Любой',
    selectRoom: 'Любоe',
  },
  toggleMenu: [
    { id: 1223, img: '#burger', isActive: true, visible: 'list' },
    { id: 2231, img: '#menu', isActive: false, visible: 'square' },
  ],
  filterSelect: {
    cellContent: {
      id: 3,
      title: 'Площадь по возрастанию',
      active: true,
    },
    choices: [
      { id: 1, title: 'Цена по возрастанию', active: false },
      {
        id: 2,
        title: 'Цена по убыванию',
        active: false,
      },
      {
        id: 3,
        title: 'Площадь по возрастанию',
        active: true,
      },
      {
        id: 4,
        title: 'Площадь по убыванию',
        active: false,
      },
    ],
  },
  list: 'list',
  redFlats: false,
  toggleRedFlats: false,
  installment: false,
  rare: false,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterName: (state, { payload }) => {
      switch (payload.name) {
        case 'selectAmountRoom':
          state.filtersSelect.selectAmountRoom = payload.desc;
          break;
        case 'selectFrame':
          state.filtersSelect.selectFrame = payload.desc;
          break;
        case 'selectRent':
          state.filtersSelect.selectRent = payload.desc;
          break;
        case 'selectRoom':
          state.filtersSelect.selectRoom = payload.desc;
          break;
        default:
          break;
      }
    },
    setToggleMenu: (state, { payload }) => {
      if (state.list !== payload.visible) {
        state.list = payload.visible;
        const newMenu = state.toggleMenu.map(el => {
          return el.id === payload.id ? { ...el, isActive: !el.isActive } : { ...el, isActive: false };
        });
        state.toggleMenu = [...newMenu];
      }
    },
    updateTableSorts(state, action) {
      const param = action.payload === 'price' ? 'real_price' : action.payload;
      if (!state.selectedСategories.includes(param)) {
        state.selectedСategories = [...state.selectedСategories, param];
      }
    },
    //Получение минимальных и максимальных параметров для фильтрации по range-слайдерам
    setExtremeSlidersValues(state) {
      if (state.allFlats.length === 0) {
        state.filterParameters.params.forEach(param => {
          const maxParam = state.filterParameters.initialValues[param][1],
            minParam = state.filterParameters.initialValues[param][0];

          state.filterParameters.slidersExtremeValues[param] = [minParam, maxParam];
          state.filterParameters.slidersValues[param] = [minParam, maxParam];
          state.filterParameters.inputsValues[param] = [minParam, maxParam];
        });
      }

      // Расчет максимумов и минимумов для слайдеров с обновленными данными
      state.filterParameters.params.forEach(param => {
        const interactedParams = state.filterParameters.interactedParams[param];

        const largestValue = calcTheLargest(state.flats, param),
          leastValue = calcTheLeast(state.flats, param, largestValue);

        const maxParam = param !== 'price' ? Math.ceil(largestValue) : Math.ceil(largestValue / 1000000) * 1000000,
          minParam = param !== 'price' ? Math.floor(leastValue) : Math.floor(leastValue / 1000000) * 1000000;

        if (
          (maxParam - minParam === 1 ||
            (param === 'price' && maxParam - minParam === 1000000) ||
            minParam === maxParam) &&
          minParam !== 0
        ) {
          const [initialMinParam, initialMaxParam] = state.filterParameters.initialValues[param];

          if (!interactedParams.min && !interactedParams.max) {
            state.filterParameters.inactiveSliders[param] = true;
            state.filterParameters.slidersValues[param] = [initialMinParam, initialMaxParam];
            state.filterParameters.inputsValues[param] = [minParam, maxParam];
            state.filterParameters.slidersExtremeValues[param] = [initialMinParam, initialMaxParam];
          }
        } else if (minParam !== 0 && minParam !== maxParam) {
          state.filterParameters.inactiveSliders[param] = false;

          if (!interactedParams.min) {
            state.filterParameters.slidersValues[param][0] = minParam;
            state.filterParameters.inputsValues[param][0] = minParam;
            state.filterParameters.slidersExtremeValues[param][0] = minParam;
          }

          if (!interactedParams.max) {
            state.filterParameters.slidersValues[param][1] = maxParam;
            state.filterParameters.inputsValues[param][1] = maxParam;
            state.filterParameters.slidersExtremeValues[param][1] = maxParam;
          }
        } else if (minParam === 0 && minParam === maxParam && !interactedParams.min && !interactedParams.max) {
          const [lastExtremeValueMin, lastExtremeValueMax] = state.filterParameters.slidersExtremeValues[param];

          state.filterParameters.inactiveSliders[param] = true;
          state.filterParameters.slidersValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
          state.filterParameters.inputsValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
        }
      });
    },

    checkRelevantAttributes(state) {
      //Делаем все характеристики пустыми
      const advantages = state?.filterParameters?.byCheckbox?.advantages?.map(item => ({
        value: item?.value,
        empty: true,
      }));

      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её не пустой
      for (let i = 0; i < state?.allFlats?.length; i++) {
        const flat = state.allFlats[i];

        Object.keys(flat?.attributes).forEach(key => {
          if (flat.attributes[key]) {
            advantages?.forEach(item => {
              if (item.value === key) {
                item.empty = false;
              }
            });
          }
        });
      }

      state.filterParameters.byCheckbox.advantages = state?.filterParameters?.byCheckbox?.advantages?.filter(
        (item, index) => !advantages[index]?.empty,
      );
    },

    //Обновить значения для отображения в изменяемом range-слайдере
    updateInputValues(state, action) {
      //Записываем новые значения в активный слайдер
      state.filterParameters.inputsValues[action.payload.slider] = [
        Number(action.payload.values[0]),
        Number(action.payload.values[1]),
      ];
    },

    //Обновить значения для расчетов в изменяемом range-слайдере
    updateSliderValues(state, action) {
      //Проверка на изменение значений - если значения отличаются от максимальных/минимальных, то
      //пользователь взаимодействовал с ползунками, записываем это в interactedParams и
      //записываем новые значения в slidersValues
      //иначе - пользователь вернул значения в исходное положение, записываем false в interactedParams
      //и записываем значения из initialValues в slidersValues
      const slider = action.payload.slider;
      const newSliderValues = [Number(action.payload.values[0]), Number(action.payload.values[1])];
      const extremeValues = state.filterParameters.slidersExtremeValues[slider];
      const initialValues = state.filterParameters.initialValues[slider];
      const newValuesAreEqual = newSliderValues[0] === newSliderValues[1];

      newSliderValues.forEach((value, index) => {
        const key = index === 0 ? 'min' : 'max';
        const preposition = index === 0 ? 'от ' : 'до ';

        if (value !== extremeValues[index]) {
          state.filterParameters.interactedParams[slider][key] = true;
          state.filterParameters.slidersValues[slider][index] = value;
          state.filterParameters.ableToResetParams[slider][index] =
            (!newValuesAreEqual ? preposition + ' ' : '') +
            Math.floor(newSliderValues[0] > 1000000 ? editPriceInMillions(value) : value);
        } else {
          state.filterParameters.interactedParams[slider][key] = false;
          state.filterParameters.slidersValues[slider][index] = initialValues[index];
          state.filterParameters.ableToResetParams[slider][index] = '';
        }
      });

      if (!action.payload.noFiltering) {
        filterSlice.caseReducers.filterFlats(state);
      }
    },

    resetParam(state, action) {
      const sliders = ['floor', 'area', 'price'];
      const checkboxes = ['rooms', 'sections', 'advantages'];
      const param = action.payload;

      if (sliders.includes(param)) {
        state.filterParameters.interactedParams[param].min = false;
        state.filterParameters.interactedParams[param].max = false;
        filterSlice.caseReducers.setExtremeSlidersValues(state);
      }

      if (checkboxes.includes(param)) {
        state.filterParameters.byCheckbox[param].forEach(item => (item.active = false));
      }

      filterSlice.caseReducers.filterFlats(state);
      state.filterParameters.ableToResetParams[param] = [];
    },
    toggleActiveSearchParams(state, action) {
      const toggleParam = (array, value) => {
        const index = array.indexOf(value);

        if (index === -1) {
          array.push(value);
        } else {
          array.splice(index, 1);
        }
      };

      filterSlice.caseReducers.updateTableSorts(state, { payload: action.payload.id });

      if (action.payload.id.includes(`${action.payload.id.slice(0, -4)}_min`)) {
        filterSlice.caseReducers.updateSliderValues(state, {
          payload: {
            slider: `${action.payload.id.slice(0, -4)}`,
            values: [
              Number(action.payload.value),
              state.filterParameters.slidersValues[`${action.payload.id.slice(0, -4)}`][1],
            ],
          },
        });
        filterSlice.caseReducers.updateInputValues(state, {
          payload: {
            slider: `${action.payload.id.slice(0, -4)}`,
            values: [
              Number(action.payload.value),
              state.filterParameters.slidersValues[`${action.payload.id.slice(0, -4)}`][1],
            ],
          },
        });
      } else if (action.payload.id.includes(`${action.payload.id.slice(0, -4)}_max`)) {
        filterSlice.caseReducers.updateSliderValues(state, {
          payload: {
            slider: `${action.payload.id.slice(0, -4)}`,
            values: [
              state.filterParameters.slidersValues[`${action.payload.id.slice(0, -4)}`][0],
              Number(action.payload.value),
            ],
          },
        });
        filterSlice.caseReducers.updateInputValues(state, {
          payload: {
            slider: `${action.payload.id.slice(0, -4)}`,
            values: [
              state.filterParameters.slidersValues[`${action.payload.id.slice(0, -4)}`][0],
              Number(action.payload.value),
            ],
          },
        });
      } else if (action.payload.id.includes('rare')) {
        filterSlice.caseReducers.toggleRare(state, { payload: action.payload.id });
      } else if (action.payload.id.includes('installment')) {
        filterSlice.caseReducers.toggleInstallment(state, { payload: action.payload.id });
      } else if (action.payload.id.includes('promo')) {
        filterSlice.caseReducers.toggleRedFlats(state, { payload: action.payload.id });
      } else if (action.payload.id.includes('rooms')) {
        state.filterParameters.byCheckbox.rooms.forEach(el => {
          el.active = false;
        });

        state.filterParameters.ableToResetParams.rooms = [];

        if (action.payload.value !== 'Любой') {
          booleanSwitch(state.filterParameters.byCheckbox, 'rooms', 'active', action.payload.value);
          toggleParam(state.filterParameters.ableToResetParams.rooms, action.payload.value);
        }
      } else if (action.payload.id.includes('sections')) {
        state.filterParameters.byCheckbox.sections.forEach(el => {
          el.active = false;
        });
        state.filterParameters.ableToResetParams.sections = [];

        if (action.payload.value !== 'Любой') {
          booleanSwitch(state.filterParameters.byCheckbox, 'sections', 'active', action.payload.value);
          toggleParam(state.filterParameters.ableToResetParams.sections, action.payload.value);
        }
      } else if (action.payload.id.includes('quarter')) {
        state.filterParameters.byCheckbox.quarter = state.filterParameters.byCheckbox.quarter.map(el => {
          return action.payload.value === el.value ? { ...el, active: true } : { ...el, active: false };
        });

        if (action.payload.value === 'Любой') {
          state.countFilteres = [...state.countFilteres.filter(el => el !== action.payload.id)];
        } else if (!state.countFilteres.includes(action.payload.id)) {
          state.countFilteres = [...state.countFilteres, action.payload.id];
        }
      } else if (action.payload.id.includes('deadline')) {
        state.filterParameters.byCheckbox.deadline = state.filterParameters.byCheckbox.deadline.map(el => {
          return action.payload.value === el.value ? { ...el, active: true } : { ...el, active: false };
        });

        if (action.payload.value === 'Любой') {
          state.countFilteres = [...state.countFilteres.filter(el => el !== action.payload.id)];
        } else if (!state.countFilteres.includes(action.payload.id)) {
          state.countFilteres = [...state.countFilteres, action.payload.id];
        }
      } else if (action.payload.id.includes('red')) {
        booleanSwitch(state.filterParameters.byCheckbox, 'redPrice', 'active', action.payload.value);
      } else if (
        action.payload.id.includes('advantages') &&
        state.filterParameters.byCheckbox &&
        state.filterParameters.byCheckbox.advantages
      ) {
        booleanSwitch(state.filterParameters.byCheckbox, 'advantages', 'active', action.payload.value);
      }

      filterSlice.caseReducers.filterFlats(state);
    },

    filterFlats(state) {
      state.filterParameters.amountOfSelectedParams = 0;

      //Фильтрация по range-слайдерам
      const interactedParams = state.filterParameters.interactedParams;

      const slidersValues = {
        price: [0, 0],
        area: [0, 0],
        floor: [0, 0],
      };

      Object.keys(interactedParams).forEach(key => {
        if (interactedParams[key].min) {
          slidersValues[key][0] = state.filterParameters.slidersValues[key][0];
        } else {
          slidersValues[key][0] = state.filterParameters.initialValues[key][0];
        }

        if (interactedParams[key].max) {
          slidersValues[key][1] = state.filterParameters.slidersValues[key][1];
        } else {
          slidersValues[key][1] = state.filterParameters.initialValues[key][1];
        }

        if (interactedParams[key].min || interactedParams[key].max) {
          state.filterParameters.amountOfSelectedParams++;
        }
      });

      const bySliders = flat => {
        return (
          flat.currentPrice >= slidersValues.price[0] &&
          flat.currentPrice <= slidersValues.price[1] &&
          flat.area >= slidersValues.area[0] &&
          flat.area <= slidersValues.area[1] &&
          flat.floor >= slidersValues.floor[0] &&
          flat.floor <= slidersValues.floor[1]
        );
      };

      const filtered = state.allFlats.filter(bySliders);

      //Фильтрация по чекбоксам

      //Получение актуальных параметров для фильтрации
      state.filterParameters.selectedParams = {
        rooms: getSelectedParams(state.filterParameters.byCheckbox, 'rooms').replace('0', 'studio'),
        sections: getSelectedParams(state.filterParameters.byCheckbox, 'sections').split(','),
        advantages: getSelectedParams(state?.filterParameters?.byCheckbox, 'advantages').split(','),
      };

      const { rooms, sections, advantages } = state.filterParameters.selectedParams;

      if (advantages[0] && advantages.length > 0) {
        state.filterParameters.amountOfSelectedParams += advantages?.length;
      }
      if (rooms && rooms !== 'Любой') {
        state.filterParameters.amountOfSelectedParams++;
      }
      if (sections[0] && !sections.includes('Любой')) {
        state.filterParameters.amountOfSelectedParams++;
      }

      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox).forEach(key => {
        state.filterParameters.byCheckbox[key].forEach(item => {
          item.disabled = !item.active;
        });
      });

      const bySections = flat => {
        return state.filterParameters.selectedParams.sections &&
          !state.filterParameters.selectedParams.sections.includes('Любой')
          ? state.filterParameters.selectedParams.sections.includes(String(flat.bulk.number))
          : true;
      };

      const byAttributes = flat => {
        for (let i = 0; i < state?.filterParameters?.selectedParams?.advantages?.length; i++) {
          if (!flat?.attributes[state?.filterParameters?.selectedParams?.advantages[i]]) {
            return false;
          }
        }
        return true;
      };

      const byRooms = flat => {
        return state.filterParameters.selectedParams.rooms &&
          !state.filterParameters.selectedParams.rooms.includes('Любой')
          ? state.filterParameters.selectedParams.rooms.includes(flat.rooms)
          : true;
      };

      const byCheckbox = {
        rooms: byRooms,
        sections: bySections,
        advantages: byAttributes,
      };

      const findRoom = flat => {
        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if (
            item.value === 'Любой' ||
            (item.value === '0' && flat.rooms === 'studio') ||
            flat.rooms === Number(item.value)
          ) {
            item.disabled = false;
          }
        });
      };

      const findSection = flat => {
        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (item.value === 'Любой' || String(flat.bulk.number) === String(item.value)) {
            item.disabled = false;
          }
        });
      };

      const findAttribute = flat => {
        state?.filterParameters?.byCheckbox?.advantages?.forEach(item => {
          if (flat.attributes[item.value]) {
            item.disabled = false;
          }
        });
      };

      const findCheckbox = {
        rooms: findRoom,
        sections: findSection,
        advantages: findAttribute,
      };

      const params = ['rooms', 'sections', 'advantages'];
      const chosenParams = [];
      Object.keys(state.filterParameters.selectedParams).forEach(key => {
        const param = state.filterParameters.selectedParams[key];
        if ((param && param.length === 0) || (param.length > 0 && param[0])) {
          chosenParams.push(key);
        }
      });

      const amountOfChosenParams = chosenParams.length;

      if (amountOfChosenParams === 0) {
        state.flats = filtered;
        filterSlice.caseReducers.setAvailableCheckboxes(state);
      }

      if (amountOfChosenParams === 1) {
        if (chosenParams[0] !== 'advantages') {
          filtered.forEach(flat => {
            findCheckbox[chosenParams[0]](flat);
          });
        }

        state.flats = filtered.filter(byCheckbox[chosenParams[0]]);
        state.flats.forEach(flat => {
          findCheckbox?.advantages(flat);
        });

        const restOfParams = params.filter(param => param !== chosenParams[0] && param !== 'advantages');

        state.flats.forEach(flat => {
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      if (amountOfChosenParams === 2) {
        const thirdParam = params.filter(param => param !== chosenParams[0] && param !== chosenParams[1])[0];

        state.flats = filtered.filter(byCheckbox[chosenParams[0]]).filter(byCheckbox[chosenParams[1]]);

        state.flats.forEach(flat => {
          findCheckbox?.advantages(flat);
          findCheckbox[thirdParam](flat);
        });

        if (chosenParams[1] !== 'advantages') {
          const filteredByFirstParam = filtered.filter(byCheckbox[chosenParams[0]]);
          filteredByFirstParam.forEach(flat => {
            findCheckbox[chosenParams[1]](flat);
          });
        }

        if (chosenParams[0] !== 'advantages') {
          const filteredBySecondParam = filtered.filter(byCheckbox[chosenParams[1]]);
          filteredBySecondParam.forEach(flat => {
            findCheckbox[chosenParams[0]](flat);
          });
        }
      }
      if (amountOfChosenParams === 3) {
        state.flats = filtered.filter(byCheckbox.sections).filter(byCheckbox.rooms).filter(byCheckbox.advantages);

        state.flats.forEach(flat => {
          findCheckbox.advantages(flat);
        });

        const filteredBySectionsAndAttributes = filtered.filter(byCheckbox.sections).filter(byCheckbox.advantages);
        filteredBySectionsAndAttributes.forEach(flat => {
          findCheckbox.rooms(flat);
        });

        const filteredByRoomsAndAttributes = filtered.filter(byCheckbox.rooms).filter(byCheckbox.advantages);
        filteredByRoomsAndAttributes.forEach(flat => {
          findCheckbox.sections(flat);
        });
      }

      filterSlice.caseReducers.setExtremeSlidersValues(state);
    },

    setAvailableCheckboxes(state) {
      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox).forEach(key => {
        state?.filterParameters?.byCheckbox[key]?.forEach(item => {
          item.disabled = !item.active;
        });
      });
      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её доступной
      for (let i = 0; i < state.flats.length; i++) {
        const flat = state.flats[i];

        Object.keys(flat.attributes).forEach(key => {
          if (flat.attributes[key]) {
            state?.filterParameters?.byCheckbox?.advantages?.forEach(item => {
              if (item.value === key) {
                item.disabled = false;
              }
            });
          }
        });

        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (item.value === 'Любой' || String(flat.bulk.number) === String(item.value)) {
            item.disabled = false;
          }
        });

        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if (
            item.value === 'Любой' ||
            (item.value === '0' && flat.rooms === 'studio') ||
            flat.rooms === Number(item.value)
          ) {
            item.disabled = false;
          }
        });
      }
    },
    setPreviousFiltersValues(state, action) {
      const { slidersValues, byCheckbox, slidersExtremeValues, inputsValues } = action.payload;
      state.filterParameters.byCheckbox = byCheckbox;
      state.filterParameters.slidersExtremeValues = slidersExtremeValues;
      state.filterParameters.inputsValues = inputsValues;

      Object.keys(slidersValues).forEach(key => {
        filterSlice.caseReducers.updateSliderValues(state, {
          payload: { values: slidersValues[key], slider: key, noFiltering: true },
        });
      });

      filterSlice.caseReducers.filterFlats(state);
    },
    resetFilters(state) {
      const checkboxParams = Object.keys(state.filterParameters.byCheckbox);
      state.selectedСategories = [];
      state.filterParameters.amountOfSelectedParams = 0;

      checkboxParams.forEach(item => {
        state.filterParameters.byCheckbox[item] = resetSortingParameter(state.filterParameters.byCheckbox[item]);
      });

      const interactedParams = Object.keys(state.filterParameters.interactedParams);
      interactedParams.forEach(item => {
        state.filterParameters.interactedParams[item].max = false;
        state.filterParameters.interactedParams[item].min = false;
      });

      Object.keys(state.filterParameters.ableToResetParams).forEach(
        key => (state.filterParameters.ableToResetParams[key] = []),
      );

      state.flats = state.allFlats;
      filterSlice.caseReducers.setExtremeSlidersValues(state);
      filterSlice.caseReducers.setAvailableCheckboxes(state);
      state.sortParameters = {
        value: 'area',
        direction: 'asc_',
      };
    },
    sortFlats(state, action) {
      // Исключаем фильтр по характеристикам
      if (
        action.payload.value !== 'advantages' &&
        action.payload.value !== 'fav' &&
        action.payload.value !== 'scheme'
      ) {
        let direction = state.sortParameters.direction;
        if (action.payload.placeCall === state.inTableHead) {
          //Если вызов произошел по клику на заголовок таблицы
          //переключить направления сортировки
          if (direction === 'asc_') {
            direction = 'desc_';
          } else {
            direction = 'asc_';
          }

          state.sortParameters = {
            value: action.payload.value,
            direction,
          };
        }

        if (action.payload.value === 'rooms') {
          const array = state.flats.map(flat => changeStudioValue(flat, 'studio', '0'));
          sortNumber(array, state.sortParameters.value, state.sortParameters.direction);
          state.flats = array.map(flat => changeStudioValue(flat, '0', 'studio'));
        } else if (action.payload.value === 'sections_number') {
          if (state.sortParameters.direction === 'asc_') {
            state.flats.sort((a, b) => a.bulk.number - b.bulk.number);
          } else {
            state.flats.sort((a, b) => b.bulk.number - a.bulk.number);
          }
        } else if (action.payload.value === 'price') {
          if (state.sortParameters.direction === 'asc_') {
            state.flats.sort((a, b) => a.currentPrice - b.currentPrice);
          } else {
            state.flats.sort((a, b) => b.currentPrice - a.currentPrice);
          }
        } else if (action.payload.value === 'real_price') {
          if (state.sortParameters.direction === 'asc_') {
            state.flats.sort((a, b) => a.currentPrice - b.currentPrice);
          } else {
            state.flats.sort((a, b) => b.currentPrice - a.currentPrice);
          }
        } else {
          sortNumber(state.flats, state.sortParameters.value, state.sortParameters.direction);
        }
      }
    },
    sortSquareFlats(state, action) {
      state.filterSelect.cellContent = { ...action.payload, active: true };
      state.filterSelect.choices = state.filterSelect.choices.map(el => {
        if (action.payload.id === el.id) {
          return { ...el, active: true };
        }
        return { ...el, active: false };
      });

      switch (action.payload.title) {
        case 'Цена по возрастанию':
          state.flats = state.flats.sort((a, b) => {
            return a.currentPrice - b.currentPrice;
          });
          break;
        case 'Цена по убыванию':
          state.flats = state.flats.sort((a, b) => b.currentPrice - a.currentPrice);
          break;
        case 'Площадь по возрастанию':
          state.flats = state.flats.sort((a, b) => {
            return a.area - b.area;
          });
          break;

        case 'Площадь по убыванию':
          state.flats = state.flats.sort((a, b) => {
            return b.area - a.area;
          });
          break;
        default:
          break;
      }
    },
    setCurrentFlat(state, action) {
      state.apartmentNumber = action.payload;
      state.currentFlat = state.flats.find(item => item.number === state.apartmentNumber);
    },
    resetCurrentFlat(state) {
      state.currentFlat = '';
      state.apartmentNumber = '';
    },
    setBenefits(state, action) {
      //При переходе на страницу записываем в стейт все доступные формы привелегий,
      //"Выгодная цена" по умолчанию активна
      state.benefits = action.payload.map(item => checkParameterAndToggleStatus(item.id, 125191, item, 116457));
      state.activeBenefit = state.benefits.find(item => item.checked === true);
    },
    toggleActiveBenefit(state, action) {
      if (action.payload !== state.activeBenefit.id) {
        state.benefits = state.benefits.map(item => checkParameterAndToggleStatus(item.id, action.payload, item));
        state.activeBenefit = state.benefits.find(item => item.checked === true);
      }
    },
    toggleRedFlats(state, action) {
      state.toggleRedFlats = action.payload;
    },
    toggleInstallment(state, action) {
      state.installment = action.payload;
    },
    toggleRare(state, action) {
      state.rare = action.payload;
    },
  },
  extraReducers: {
    [fetchFlats.pending]: state => {
      state.urls.getResidential.status = 'loading';
      state.urls.getResidential.error = null;
    },
    [fetchFlats.fulfilled]: (state, action) => {
      state.flats = action.payload.flats;
      state.allFlats = action.payload.flats;
      const maxFloor = calcTheLargest(state.flats, 'floor');
      const minFloor = calcTheLeast(state.flats, 'floor', maxFloor);
      const minPrice = action.payload.price.min,
        maxPrice = action.payload.price.max,
        minArea = action.payload.area.min,
        maxArea = action.payload.area.max;

      if (maxFloor && minFloor && minPrice && maxPrice && minArea && maxArea) {
        state.filterParameters.initialValues = {
          area: [minArea, maxArea],
          price: [minPrice, maxPrice],
          floor: [minFloor, maxFloor],
        };
      } else {
        state.filterParameters.initialValues = {
          area: [2, 20],
          price: [2, 20],
          floor: [2, 20],
        };
      }

      const maxFloorFromAllFlats = calcTheLargest(state.allFlats, 'floor');
      state.maxFloor = maxFloorFromAllFlats;

      // filterSlice.caseReducers.checkRelevantAttributes(state);
      filterSlice.caseReducers.setExtremeSlidersValues(state);
      filterSlice.caseReducers.setAvailableCheckboxes(state);

      state.redFlats = action.payload?.flats?.filter(item => item.redPrice).length > 0;
      state.urls.getResidential.initial = true;
      state.urls.getResidential.status = true;

      filterSlice.caseReducers.sortFlats(state, {
        payload: {
          value: state.sortParameters.value,
          placeCall: state.inForm,
          array: 'flats',
        },
      });
    },
    [fetchFlats.rejected]: state => {
      console.log('Ошибка при запросе на сервер');
      state.urls.getResidential.error = 'error';
      state.urls.getResidential.status = null;
    },

    [fetchFlat.pending]: () => {},
    [fetchFlat.fulfilled]: (state, action) => {
      state.flat = action.payload;
      state.similarFlats = action.payload.similar;
    },
    [fetchFlat.rejected]: () => {
      console.log('Ошибка загрузки квартиры');
    },

    [fetchAttributes.pending]: () => {},
    [fetchAttributes.fulfilled]: (state, action) => {
      const modifiedAttributes = action.payload.map(attr => {
        return {
          id: attr.id,
          value: attr.name,
          name: attr.description,
          category: attr.category,
          active: false,
          disabled: false,
        };
      });
      state.filterParameters.byCheckbox.advantages = modifiedAttributes;
      filterSlice.caseReducers.checkRelevantAttributes(state);
    },
    [fetchAttributes.rejected]: () => {
      console.log('Ошибка загрузки атрибутов');
    },
  },
});

export const {
  updateTableSorts,
  toggleActiveSearchParams,
  sortFlats,
  sortSquareFlats,
  resetFilters,
  setToggleMenu,
  updateInputValues,
  updateSliderValues,
  setPreviousFiltersValues,
  resetParam,
  toggleRedFlats,
  toggleInstallment,
  toggleRare,
} = filterSlice.actions;

export default filterSlice.reducer;
