import { useNavigate, useParams } from 'react-router-dom';
import style from './Tab.module.scss';

const Tab = ({ text, link, mobTabActiveStyle, mobTabStyle, mobTabBehindStyle }) => {
  const navigate = useNavigate();
  const { houseId, floorId, bulkId } = useParams();

  if (!houseId && text === 'Очередь') {
    return (
      <button className={style.tab__active + ' ' + mobTabActiveStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (!houseId) {
    return (
      <button disabled className={style.tab + ' ' + mobTabStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }

  if (houseId && !bulkId && text === 'Корпус') {
    return (
      <button className={style.tab__active + ' ' + mobTabActiveStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (houseId && !bulkId && text === 'Очередь') {
    return (
      <button className={style.tab__behind + ' ' + mobTabBehindStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (houseId && !bulkId) {
    return (
      <button disabled className={style.tab + ' ' + mobTabStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }

  if (houseId && bulkId && !floorId && text === 'Этаж') {
    return (
      <button className={style.tab__active + ' ' + mobTabActiveStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (houseId && bulkId && !floorId && text === 'Квартира') {
    return (
      <button disabled className={style.tab + ' ' + mobTabStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (houseId && bulkId && !floorId && (text === 'Очередь' || 'Корпус')) {
    return (
      <button className={style.tab__behind + ' ' + mobTabBehindStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }

  if (houseId && bulkId && floorId && text === 'Квартира') {
    return (
      <button className={style.tab__active + ' ' + mobTabActiveStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
  if (houseId && bulkId && floorId) {
    return (
      <button className={style.tab__behind + ' ' + mobTabBehindStyle} onClick={() => navigate(link)}>
        {text}
      </button>
    );
  }
};

export default Tab;
