import { isMobile } from 'react-device-detect';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../../../layout/routes';
import style from './NextLink.module.scss';

function NextLink({ activeFloor, activeFlat, bulk, activeQueue, flatId }) {
  const { houseId } = useParams();
  const { floorId } = useParams();
  const { apartmentId } = useParams();
  const { bulkId } = useParams();
  const text = floorId && !isMobile ? 'Выбрать квартиру' : floorId && isMobile ? 'Выбрать' : 'Продолжить';

  return (
    <>
      {!houseId && activeQueue === 2 ? (
        <Link to={`${ROUTES.flats.visual.root}/${activeQueue}`}>
          <div className={style.nextButton}>{text}</div>
        </Link>
      ) : !houseId && activeQueue === 1 ? (
        <Link to={`${ROUTES.flats.visual.root}/${activeQueue}/9335`}>
          <div className={style.nextButton}>{text}</div>
        </Link>
      ) : null}
      {!floorId && houseId && !bulkId && (bulk.toString() === '9758' || bulk.toString() === '10099') && (
        <Link to={`${ROUTES.flats.visual.root}/${houseId}/${bulk}`}>
          <div className={style.nextButton}>{text}</div>
        </Link>
      )}
      {!floorId && activeFloor && (
        <Link to={`${ROUTES.flats.visual.root}/${houseId}/${bulkId}/${activeFloor}`}>
          <div className={style.nextButton}>{text}</div>
        </Link>
      )}
      {floorId && !apartmentId && activeFlat !== null && (
        <Link to={`/flat/${flatId}`}>
          <div className={style.nextButton}>{text}</div>
        </Link>
      )}
    </>
  );
}

export default NextLink;
