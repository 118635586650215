import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import api from './api';

export const fetchProgress = createAsyncThunk('progressSlice/fetchProgress', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlProgress);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

export const fetchProgressCompareSlider = createAsyncThunk(
  'progressSlice/fetchProgressCompareSlider',
  async (_, thunkApi) => {
    try {
      const response = await axios.get(api.urlProgressCompareSlider);
      return response.data;
    } catch (error) {
      return onApiError(thunkApi, error);
    }
  },
);

const initialState = {
  progress: null,
  stageOne: [],
  stageTwo: [],
  stageThree: [],
  activeStage: 0,
  tabs: [
    { text: 'Все', stage: 0 },
    { text: '1 очередь', stage: 1 },
    { text: '2 очередь', stage: 2 },
    { text: '3 очередь', stage: 3 },
  ],
  compareSlider: {
    future: null,
    present: null,
  },
  fetchError: false,
};

export const progressSlice = createSlice({
  name: 'progressSlice',
  initialState,
  reducers: {
    setActiveStage: (state, action) => {
      state.activeStage = action.payload;
    },
  },
  extraReducers: {
    [fetchProgress.fulfilled]: (state, action) => {
      state.progress = action.payload;
      action.payload.forEach(item => {
        if (item.number_stage === 1) {
          state.stageOne.push(item);
        } else if (item.number_stage === 2) {
          state.stageTwo.push(item);
        } else if (item.number_stage === 3) {
          state.stageThree.push(item);
        }
      });
    },
    [fetchProgress.rejected]: state => {
      state.fetchError = true;
    },
    [fetchProgressCompareSlider.fulfilled]: (state, action) => {
      action.payload.absolutePath.forEach(path => {
        if (path.includes('future')) state.compareSlider.future = path;
        if (path.includes('now')) state.compareSlider.present = path;
      });
    },
    [fetchProgressCompareSlider.rejected]: state => {
      state.fetchError = true;
    },
  },
});

export const { setActiveStage } = progressSlice.actions;

export default progressSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
