import { useLayoutEffect, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { deviceSelector } from '../../entities/UserSlice';
import SVGelem from '../../shared/SVGelem/SVGelem';
import useScroll from '../../tools/hooks/useScroll';
import style from './ButtonToTop.module.scss';

function ButtonToTop({ className }) {
  const { scroll } = useLocomotiveScroll();
  const device = useSelector(deviceSelector);
  const { scrollY } = useScroll();
  const [toggle, setToggle] = useState(false);
  const [toggleBottom, setToggleBottom] = useState(false);

  useLayoutEffect(() => {
    if (device === 'isMobile') {
      if (scrollY > window.innerHeight / 3) {
        setToggle(true);
      } else {
        setToggle(false);
      }

      if (scrollY >= document.body.scrollHeight - window.innerHeight - (200 / 375) * window.innerWidth) {
        setToggleBottom(true);
      } else {
        setToggleBottom(false);
      }
    }

    if (device !== 'isMobile' && scroll && scrollY === 0) {
      scroll.on('scroll', () => {
        if (scroll.scroll.instance.scroll.y > window.innerHeight / 2) {
          setToggle(true);
        } else if (scroll.scroll.instance.scroll.y <= window.innerHeight / 2) {
          setToggle(false);
        }

        if (scroll.scroll.instance.scroll.y >= scroll.scroll.instance.limit.y - (200 / 1600) * window.innerWidth) {
          setToggleBottom(true);
        } else {
          setToggleBottom(false);
        }
      });
    }

    if ((device !== 'isMobile' && !scroll) || (device !== 'isMobile' && scroll && scrollY !== 0)) {
      if (scrollY > window.innerHeight / 2) {
        setToggle(true);
      } else {
        setToggle(false);
      }

      if (scrollY >= document.body.scrollHeight - window.innerHeight - (200 / 1600) * window.innerWidth) {
        setToggleBottom(true);
      } else {
        setToggleBottom(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, scrollY]);

  const scrollToTop = () => {
    scroll.scrollTo(0, 0);
  };

  const scrollToTopMobile = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleUp = () => {
    if (device === 'isMobile' || (device !== 'isMobile' && scrollY !== 0)) {
      scrollToTopMobile();
    } else scrollToTop();
  };

  const animatedStyle = useSpring({
    config: {
      duration: 800,
      mass: 1,
      tension: 280,
      friction: 120,
    },
    opacity: toggle ? 1 : 0,
    delay: 0,
    top: toggleBottom ? '35vh' : '80vh',
  });

  const animatedStyleMob = useSpring({
    config: {
      duration: 800,
      mass: 1,
      tension: 280,
      friction: 120,
    },

    opacity: toggle ? 1 : 0,
    delay: 0,
    top: toggleBottom ? '25vh' : '70vh',
  });

  return (
    <animated.button
      data-scroll
      data-scroll-sticky
      data-scroll-target='#fixed-target'
      style={device !== 'isMobile' ? animatedStyle : animatedStyleMob}
      className={style.button}
      onClick={() => handleUp()}
    >
      <SVGelem href='#arrow-small-white' className={style.buttonIcon} />
    </animated.button>
  );
}

export default ButtonToTop;
