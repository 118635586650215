import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchNews = createAsyncThunk('newsSlice/fetchNews', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlNews);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

export const fetchSlider = createAsyncThunk('newsSlice/fetchSlider', async (sliderName, thunkApi) => {
  try {
    const response = await axios.get(`/api/slider/${sliderName}`);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const initialState = {
  news: null,
  slider: null,
  fetchError: false,
};

export const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNews.fulfilled]: (state, action) => {
      state.news = action.payload;
    },
    [fetchNews.rejected]: (state, action) => {
      state.fetchError = true;
    },
    [fetchSlider.fulfilled]: (state, action) => {
      state.slider = action.payload;
    },
    [fetchSlider.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const newsSelector = state => state.newsInfo.news;
export const sliderSelector = state => state.newsInfo.slider;
export const fetchErrorSelector = state => state.newsInfo.fetchError;

export default newsSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
