import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ROUTES from '../../layout/routes';
import Tab from '../tab-button/Tab';
import style from './VisualNav.module.scss';

const VisualNav = ({ activeQueue, mobTabStyle, mobTabActiveStyle, mobTabBehindStyle }) => {
  const { houseId, bulkId, floorId } = useParams();

  const rootLink = `${ROUTES.flats.visual.root}`;
  const bulkLink = `${ROUTES.flats.visual.root}/${houseId}`;
  const floorLink = `${ROUTES.flats.visual.root}/${houseId}/${bulkId}`;
  const flatLink = `${ROUTES.flats.visual.root}/${houseId}/${bulkId}/${floorId}`;

  const [fourTabs, setFourTabs] = useState([
    { id: 0, text: 'Очередь', isActive: true, link: rootLink, isBehind: false },
    { id: 1, text: 'Корпус', isActive: false, link: bulkLink, isBehind: false },
    { id: 2, text: 'Этаж', isActive: false, link: floorLink, isBehind: false },
    { id: 3, text: 'Квартира', isActive: false, link: flatLink, isBehind: false },
  ]);

  const [threeTabs, setThreeTabs] = useState([
    { id: 0, text: 'Очередь', isActive: true, link: rootLink, isBehind: false },
    { id: 1, text: 'Этаж', isActive: false, link: floorLink, isBehind: false },
    { id: 2, text: 'Квартира', isActive: false, link: flatLink, isBehind: false },
  ]);

  return (
    <>
      {activeQueue === 2 &&
        fourTabs.map(item => {
          return (
            <Tab
              key={item.id}
              text={item.text}
              isActive={item.isActive}
              link={item.link}
              mobTabActiveStyle={mobTabActiveStyle}
              mobTabStyle={mobTabStyle}
            />
          );
        })}
      {(activeQueue === 1 || activeQueue === null) &&
        threeTabs.map(item => {
          return (
            <Tab
              key={item.id}
              text={item.text}
              isActive={item.isActive}
              link={item.link}
              mobTabActiveStyle={mobTabActiveStyle}
              mobTabStyle={mobTabStyle}
            />
          );
        })}

      {houseId === '2' &&
        fourTabs.map(item => {
          return (
            <Tab
              key={item.id}
              text={item.text}
              isActive={item.isActive}
              link={item.link}
              mobTabActiveStyle={mobTabActiveStyle}
              mobTabStyle={mobTabStyle}
              mobTabBehindStyle={mobTabBehindStyle}
            />
          );
        })}

      {houseId === '1' &&
        threeTabs.map(item => {
          return (
            <Tab
              key={item.id}
              text={item.text}
              isActive={item.isActive}
              link={item.link}
              mobTabActiveStyle={mobTabActiveStyle}
              mobTabStyle={mobTabStyle}
              mobTabBehindStyle={mobTabBehindStyle}
            />
          );
        })}
    </>
  );
};

export default VisualNav;
