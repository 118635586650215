import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scroll: false,
  openModelError: false,
  openModelForm: false,
  favoriteInputForm: false,
  openMenu: false,
  openMenuMobile: false,
  openEmailForm: false,
  openFilterMenu: false,
  openFavoriteMenuModal: false,
  openPanoramaModel: false,
  visibleScroll: false,
  visibleHeader: false,
  headerColorScheme: '', // whiteOnTransparent, multiOnTransparent, "" пустая строка для черного на белом
  notFoundPage: false,
  isLoading: false,
  scrollDestroy: false,
  openModalSort: false,
  panoramaImgsLoading: 'pendind',
  startPanoramaPreload: false,
  openFullScreenGallery: false,
  copiedLinkFlat: false,
  formName: null,
  scrollUpdate: false,
  openTradeInForm: false,
  hiddenMessageFormCallBack: null,
  openCameraModal: false,
  openVideoModal: false,
  openParkingFilters: false,
  openParkingSort: false,
  mortgageCost: 0,
  mortgageInitialPayment: 0,
  mortgageTerm: 0,
  familyMortgage: false,
};

export const ComponentsSlice = createSlice({
  name: 'componentsstate',
  initialState,
  reducers: {
    handleStopScroll: (state, action) => {
      state.scroll = action.payload;
    },
    handleOpenModelForm: (state, action) => {
      state.openModelForm = action.payload;
    },
    handleOpenMenu: (state, action) => {
      state.openMenu = action.payload;
    },
    handleOpenMenuMobile: (state, action) => {
      state.openMenuMobile = action.payload;
    },
    handleFavoriteMenuModal: (state, action) => {
      state.openFavoriteMenuModal = action.payload;
    },
    handleOpenEmailForm: (state, action) => {
      state.openEmailForm = action.payload;
    },
    handleOpenFilterMenu: (state, action) => {
      state.openFilterMenu = action.payload;
    },
    handlePanoramaModel: (state, action) => {
      state.openPanoramaModel = action.payload;
    },
    handleScrollVisible: (state, action) => {
      state.visibleScroll = action.payload;
    },
    handleVisibleHeader: (state, action) => {
      state.visibleHeader = action.payload;
    },
    handleHeaderColorScheme: (state, action) => {
      state.headerColorScheme = action.payload;
    },
    handleNotFoundPage: (state, action) => {
      state.notFoundPage = action.payload;
    },
    handleIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    handleOpenModelError: (state, action) => {
      state.openModelError = action.payload;
    },
    handleFavoriteInputForm: (state, action) => {
      state.favoriteInputForm = action.payload;
    },
    handleScrollDestroy: (state, action) => {
      state.scrollDestroy = action.payload;
    },
    handleOpenModalSort: (state, action) => {
      state.openModalSort = action.payload;
    },
    handlepanoramaImgsLoading: (state, action) => {
      state.panoramaImgsLoading = action.payload;
    },
    handleStartPanoramaPreload: (state, action) => {
      state.startPanoramaPreload = action.payload;
    },
    handleOpenFullScreenGallery: (state, action) => {
      state.openFullScreenGallery = action.payload;
    },

    handleCopiedLinkFlat: (state, action) => {
      state.copiedLinkFlat = action.payload;
    },
    handleChangeFormName: (state, action) => {
      state.formName = action.payload;
    },
    handleScrollUpdate: (state, action) => {
      state.scrollUpdate = action.payload;
    },
    handleOpenCameraModal: (state, action) => {
      state.openCameraModal = action.payload;
    },
    handleOpenTradeInForm: (state, action) => {
      state.openTradeInForm = action.payload;
    },
    handleChangeHiddenMessageFormCallBack: (state, action) => {
      state.hiddenMessageFormCallBack = action.payload;
    },
    handleOpenVideoModal: (state, action) => {
      state.openVideoModal = action.payload;
    },
    handleOpenParkingFilters: (state, action) => {
      state.openParkingFilters = action.payload;
    },
    handleOpenParkingSort: (state, action) => {
      state.openParkingSort = action.payload;
    },
    handleChangeMortgageCost: (state, action) => {
      state.mortgageCost = action.payload;
    },
    handleChangeMortgageInitialPayment: (state, action) => {
      state.mortgageInitialPayment = action.payload;
    },
    handleChangeMortgageTerm: (state, action) => {
      state.mortgageTerm = action.payload;
    },
    setFamilyMortgage: (state, action) => {
      state.familyMortgage = action.payload;
    },
  },
});

export const {
  handleOpenModelForm,
  handleScrollVisible,
  handlePanoramaModel,
  handleOpenFilterMenu,
  handleOpenMenuMobile,
  handleOpenMenu,
  handleFavoriteMenuModal,
  handleVisibleHeader,
  handleHeaderColorScheme,
  handleNotFoundPage,
  handleIsLoading,
  handleStopScroll,
  handleOpenEmailForm,
  handleOpenModelError,
  handleFavoriteInputForm,
  handleScrollDestroy,
  handleOpenModalSort,
  handlepanoramaImgsLoading,
  handleStartPanoramaPreload,
  handleOpenFullScreenGallery,
  handleCopiedLinkFlat,
  handleChangeFormName,
  handleScrollUpdate,
  handleOpenCameraModal,
  handleOpenTradeInForm,
  handleChangeHiddenMessageFormCallBack,
  handleOpenVideoModal,
  handleOpenParkingFilters,
  handleOpenParkingSort,
  handleChangeMortgageCost,
  handleChangeMortgageInitialPayment,
  handleChangeMortgageTerm,
  setFamilyMortgage,
} = ComponentsSlice.actions;

export const modelErrorSelector = state => state.componentsstate.openModelError;
export const modelFormSelector = state => state.componentsstate.openModelForm;
export const menuSelector = state => state.componentsstate.openMenu;
export const menuMobileSelector = state => state.componentsstate.openMenuMobile;
export const emailFormSelector = state => state.componentsstate.openEmailForm;
export const filterMenuSelector = state => state.componentsstate.openFilterMenu;
export const panoramaModelSelector = state => state.componentsstate.openPanoramaModel;
export const scrollDestroySelector = state => state.componentsstate.scrollDestroy;
export const pamoramasSelector = state => state.componentsstate.panoramaImgsLoading;
export const scrollSelector = state => state.componentsstate.scroll;
export const favoriteInputFormSelector = state => state.componentsstate.favoriteInputForm;
export const openFavoriteMenuModalSelector = state => state.componentsstate.openFavoriteMenuModal;
export const visibleScrollSelector = state => state.componentsstate.visibleScroll;
export const visibleHeaderSelector = state => state.componentsstate.visibleHeader;
export const headerColorSchemeSelector = state => state.componentsstate.headerColorScheme;
export const notFoundPageSelector = state => state.componentsstate.notFoundPage;
export const isLoadingSelector = state => state.componentsstate.isLoading;
export const openModalSortSelector = state => state.componentsstate.openModalSort;
export const startPanoramaPreloadSelector = state => state.componentsstate.startPanoramaPreload;
export const fullScreenGallerySelector = state => state.componentsstate.openFullScreenGallery;
export const copiedLinkFlatSelector = state => state.componentsstate.copiedLinkFlat;
export const formNameSelector = state => state.componentsstate.formName;
export const scrollUpdateSelector = state => state.componentsstate.scrollUpdate;
export const cameraModalSelector = state => state.componentsstate.openCameraModal;
export const openTradeInFormSelector = state => state.componentsstate.openTradeInForm;
export const hiddenMessage = state => state.componentsstate.hiddenMessageFormCallBack;
export const videoModalSelector = state => state.componentsstate.openVideoModal;
export const openParkingFiltersSelector = state => state.componentsstate.openParkingFilters;
export const openParkingSortSelector = state => state.componentsstate.openParkingSort;
export const mortgageCostSelector = state => state.componentsstate.mortgageCost;
export const mortgageInitialPaymentSelector = state => state.componentsstate.mortgageInitialPayment;
export const mortgageTermSelector = state => state.componentsstate.mortgageTerm;
export const familyMortgageSelector = state => state.componentsstate.familyMortgage;

export default ComponentsSlice.reducer;
