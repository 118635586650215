import { useUserService } from '../../tools/hooks/useUserService';
import style from './LoaderCircle.module.scss';

const LoaderCircle = ({ isLoading, someStyles }) => {
  const { vendor } = useUserService();

  return (
    <div className={style.nl_reploader_father + ' ' + `${isLoading ? style.__hide : ''}` + ' ' + someStyles}>
      <div className={style.nl_preloader}>
        {(!vendor || (vendor && !vendor.includes('Apple'))) && <span className={style.loader} />}
        {vendor && vendor.includes('Apple') && <span className={style.loaderApple} />}
      </div>
    </div>
  );
};

export default LoaderCircle;
