import cn from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { deviceSelector } from '../../entities/UserSlice';
import style from './Image.module.scss';

const Image = ({
  dataScrollPosition,
  dataScroll,
  dataScrollSpeed,
  dataScrollTarget,
  speed,
  offset,
  end,
  start,
  className,
  src,
  srcWebP,
  src375,
  src375Web,
  name,
  onMove,
  imagePlug,
  height,
}) => {
  gsap.registerPlugin(ScrollTrigger);
  const device = useSelector(deviceSelector);

  const ref = useRef();

  let t6 = gsap.timeline();

  useLayoutEffect(() => {
    if (ref) {
      const el = ref.current;

      setTimeout(() => {
        t6.to(el, {
          y: (i, el) => (1 - Number.parseFloat(speed)) * offset,
          ease: 'none',
          scrollTrigger: {
            id: 'trigger6',
            start: start ? start : 0,
            end,
            scroller: device !== 'isMobile' ? '.App' : 'body',
            invalidateOnRefresh: false,
            scrub: 0,
          },
        });
        ScrollTrigger.refresh();
      }, 1000);
    }
  }, [ref]);

  useEffect(() => {
    return () => {
      if (ScrollTrigger.getById('trigger6')) {
        t6.kill();
        ScrollTrigger.getById('trigger6').kill();
        t6 = null;
      }
    };
  }, []);

  return (
    <div
      data-scroll={dataScroll}
      data-scroll-speed={dataScrollSpeed}
      data-scroll-position={dataScrollPosition}
      data-scroll-target={dataScrollTarget}
      ref={ref}
      className={cn(style.imgContainer, className)}
      style={{ backgroundSize: 'cover', backgroundImage: `url(${imagePlug})` }}
    >
      <picture>
        <source type='image/webp' srcSet={src375Web} media='(min-width: 220px) and (max-width: 768px)' />
        <source type='image/webp' srcSet={srcWebP} media='(min-width: 769px)' />
        <source srcSet={src375} media='(min-width: 220px) and (max-width: 768px)' />
        <img
          style={{ backgroundSize: 'cover', backgroundImage: `url(${imagePlug})` }}
          loading='lazy'
          decoding='async'
          src={src}
          alt={name}
          width='100%'
          height={height ? height : 'auto'}
        />
      </picture>
    </div>
  );
};
export default Image;
