import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from './api';

export const fetchNavList = createAsyncThunk('termsSlice/fetchNavList', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlNavList);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchTradeIn = createAsyncThunk('termsSlice/fetchTradeIn', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlTradeIn);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchQa = createAsyncThunk('termsSlice/fetchQa', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlQa);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchSpecialMortgages = createAsyncThunk('termsSlice/fetchSpecialMortgages', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlSpecialMortgages);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchMortgages = createAsyncThunk('termsSlice/fetchMortgages', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlMortgages);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchCalcData = createAsyncThunk('termsSlice/fetchCalcData', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlCalcData);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchInstallments = createAsyncThunk('termsSlice/fetchInstallments', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlInstallments);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchSpecialInstallments = createAsyncThunk('termsSlice/fetchSpecialInstallments', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlSpecialInstallments);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchCash = createAsyncThunk('termsSlice/fetchCash', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlCash);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchCashback = createAsyncThunk('termsSlice/fetchCashback', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlCashback);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchPromoCards = createAsyncThunk('termsSlice/fetchPromoCards', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlPromoCards);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
export const fetchAllFlats = createAsyncThunk('termsSlice/fetchAllFlats', async (_, thunkApi) => {
  try {
    const response = await axios.get(api.urlFlats);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
const initialState = {
  navList: null,
  tradeIn: null,
  qa: null,
  specialMortgages: null,
  mortgages: null,
  calcData: null,
  installments: null,
  specialInstallments: null,
  cash: null,
  flats: null,
  fetchError: false,
  cashback: null,
  promoCards: null,
  activeTab: 0,
};

export const termsSlice = createSlice({
  name: 'termsSlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: {
    [fetchNavList.fulfilled]: (state, action) => {
      state.navList = action.payload;
    },
    [fetchNavList.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchTradeIn.fulfilled]: (state, action) => {
      state.tradeIn = action.payload;
    },
    [fetchTradeIn.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchQa.fulfilled]: (state, action) => {
      state.qa = action.payload;
    },
    [fetchQa.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchSpecialMortgages.fulfilled]: (state, action) => {
      state.specialMortgages = action.payload;
    },
    [fetchSpecialMortgages.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchMortgages.fulfilled]: (state, action) => {
      state.mortgages = action.payload;
    },
    [fetchMortgages.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCalcData.fulfilled]: (state, action) => {
      state.calcData = action.payload;
    },
    [fetchCalcData.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchInstallments.fulfilled]: (state, action) => {
      state.installments = action.payload;
    },
    [fetchInstallments.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchSpecialInstallments.fulfilled]: (state, action) => {
      state.specialInstallments = action.payload;
    },
    [fetchSpecialInstallments.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCash.fulfilled]: (state, action) => {
      state.cash = action.payload;
    },
    [fetchCash.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchCashback.fulfilled]: (state, action) => {
      state.cashback = action.payload;
    },
    [fetchCashback.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchPromoCards.fulfilled]: (state, action) => {
      state.promoCards = action.payload;
    },
    [fetchPromoCards.rejected]: (state, action) => {
      state.fetchError = true;
    },

    [fetchAllFlats.fulfilled]: (state, action) => {
      state.flats = action.payload;
    },
    [fetchAllFlats.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const { setActiveTab } = termsSlice.actions;

export const navListSelector = state => state.termsInfo.navList;
export const calcDataSelector = state => state.termsInfo.calcData;
export const specialInstallmentsSelector = state => state.termsInfo.specialInstallments;
export const installmentsSelector = state => state.termsInfo.installments;
export const cashSelector = state => state.termsInfo.cash;
export const cashbackSelector = state => state.termsInfo.cashback;
export const promoCardsSelector = state => state.termsInfo.promoCards;
export const mortgagesSelector = state => state.termsInfo.mortgages;
export const specialMortgagesSelector = state => state.termsInfo.specialMortgages;
export const qaSelector = state => state.termsInfo.qa;
export const tradeInSelector = state => state.termsInfo.tradeIn;
export const fetchErrorSelector = state => state.termsInfo.fetchError;
export const activeTabSelector = state => state.termsInfo.activeTab;

export default termsSlice.reducer;

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
